import {Accordion, AccordionDetails, AccordionSummary, Card} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AccordionWrapper({card = false, parent, children}) {
	// expandIcon={<ExpandMoreIcon />}
	return (
		<Accordion sx={{p: 0, m: 0, flex: 1}}>
			{card ? (
				<Card>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{p: 0, pr: 1, m: 0, flex: 1}}>
						{parent}
					</AccordionSummary>
				</Card>
			) : (
				<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{p: 0, pr: 1, m: 0, flex: 1}}>
					{parent}
				</AccordionSummary>
			)}

			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
}
