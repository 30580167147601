import {Stack, Typography} from '@mui/material';
import React from 'react';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import Row from 'src/components/ui/Row';
import CopyButton from 'src/components/buttons/CopyButton';

const bgcolor = '#EDEEF4';
export default function OrderCustomerDetails({order}) {
	const {phone_country_code = '+218'} = order;
	return !isNullOrEmpty(order.customer) ? (
		<>
			<Row bgcolor={bgcolor} label="الاسم" value={order.customer?.name || 'لم يتم ادخال الاسم'} />
			{/* phone number */}
			{!isNullOrEmpty(order.customer.phone) && (
				<Row
					label="رقم الهاتف"
					value={
						<Stack direction={'row'} alignItems="center">
							<a
								style={{
									textDecoration: 'none',
									color: '#515151',
									border: 'solid 0.3px #515151',
									padding: 6,
									fontSize: 14,
									borderRadius: 10,
									direction: 'ltr',
								}}
								href={`tel://${phone_country_code}${order.customer.phone}`}
							>
								اتصال {`${order.customer.phone}`}
								{/* اتصال {`${phone_country_code}${order.customer.phone}`} */}
							</a>
							<CopyButton value={`${phone_country_code}${order.customer.phone}`} />
						</Stack>
					}
				/>
			)}
		</>
	) : (
		<Typography variant="body" color="error">
			لم يتم العثور علي بيانات الزبون
		</Typography>
	);
}
