// products
export const findProductPermission = 'products.find';
export const findOneProductPermission = 'products.findone';
export const addProductPermission = 'products.create';
export const deleteProductPermission = 'products.delete';
export const updateProductPermission = 'products.update';
// orders
export const findOneOrderPermission = 'orders.findone';
export const findOrderPermission = 'orders.find';
export const createOrderPermission = 'orders.create';
export const deleteOrderPermission = 'orders.delete';
export const updateOrderPermission = 'orders.update';
export const updateOrderStatusPermission = 'orders.updateStatus';
