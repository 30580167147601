import {useParams} from 'react-router-dom';
// material
import {Button, Card, Container, Divider, IconButton, Stack, Typography} from '@mui/material';

// components
import {OrderItemsList} from './OrderItems.list';

import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog';
import DeleteWithReasonModal from 'src/components/modals/DeleteWithReason';
import {isNullOrEmpty} from 'src/utils/helperMethods';

import ReceiptIcon from '@mui/icons-material/Receipt';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import NavButton from 'src/components/NavButton';
import OrderCustomerDetails from 'src/pages/Orders/show/OrderCustomerDetails';
import OrderDetailsSummury from 'src/pages/Orders/show/OrderDetailsSummury';
import OrderShippingDetails from 'src/pages/Orders/show/OrderShippingDetails';
import OrderStatusPicker from 'src/pages/Orders/show/OrderStatusPicker';
import Page from 'src/components/Page';
import OrderAdditionalDetails from 'src/pages/Orders/show/OrderAdditionalDetails';
import OrderAssignPicker, {useOrderAssign} from 'src/pages/Orders/show/OrderAssignPicker';
import ShippingProvidersActions from 'src/pages/Orders/show/ShippingProvidersActions';
import InfoTip from 'src/components/ui/InfoTip';
import {printJsonReceipt} from 'src/features/printer';
import {
	createOrderPermission,
	deleteOrderPermission,
	findOneOrderPermission,
	updateOrderPermission,
	updateOrderStatusPermission,
} from 'src/permissionsList';
import {orderStatus} from 'src/utils/orderUtils';
import {canAccess, isOrderAssignee} from 'src/utils/permissions';
import {accountIs} from 'src/utils/siteUtils';
import useOrderDetails from '../../../hooks/useOrderDetails';
import {sendOrderToPrinterco} from 'src/plugins/printerco/printercoApi';
import thirdPartyApps from 'src/pages/apps/thirdPartyApps';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import InvoiceItem from 'src/pages/Invoices/InvoiceItem';

const bgcolor = '#EDEEF4';
export default function OrderDetails() {
	// hooks
	let {id} = useParams(); // this will take order id from url path
	let {
		order,
		setOrder,
		shippingDataIsLoading,
		shipping,
		shippingTimelines,
		itemToDelete,
		setItemToDelete,
		shouldCancelOrder,
		setShouldCancelOrder,
		onSendShippmentClicked,
		onDeleteShippingClicked,
		handleCancelOrder,
		onStatusChange,
		handleDeleteItemSubmit,
		onDeleteOrder,
		dublicate,
		receivePayment,
		handleReturnOrder,
	} = useOrderDetails(id);
	const {allAdmins, onAssigneeChange} = useOrderAssign({
		orderUUID: order?.uuid,
	});
	// variables
	const deliveryType = order?.delivery_info?.type ?? 'delivery';

	if (!order) return null;
	const order_closed =
		order.is_cashier && (order.status === 'cancelled' || order.status === 'returned');
	const canUpdateOrder = canAccess(updateOrderPermission);

	const canUpdateOrderStatus =
		canUpdateOrder || isOrderAssignee(order) || canAccess(updateOrderStatusPermission);

	return (
		<Page title="معلومات الطلب">
			<AccessControlWrapper
				controllerAction={findOneOrderPermission}
				// we will allow user to access this page if he is assigned to it
				or={isOrderAssignee(order)}
			>
				<Container>
					<Stack sx={{mb: 2}} direction="row" alignItems="center" justifyContent="space-between">
						<Stack>
							<Typography variant="h4">{' الطلب ' + '#' + order.tracking_number}</Typography>
							{/* show it only to owner */}
							{order.creator && canAccess() && (
								<Typography variant="body2">بواسطة : {order.creator.name}</Typography>
							)}
						</Stack>

						{/* delete icon button when clicked show model to confirm the action */}
						<Stack spacing={2} direction="row" justifyContent="space-between">
							{/* <IconButton size="small" onClick={() => printOrderReceipt(order.id, order.uuid)}> */}
							<Button
								endIcon={<ReceiptIcon sx={{fontSize: 16}} />}
								size="small"
								onClick={() => printJsonReceipt(order)}
							>
								طباعة فاتورة
							</Button>
							{/* <IconButton size="small" onClick={() => printJsonReceipt(order)}>
								<Typography>طباعة فاتورة</Typography>
								<ReceiptIcon sx={{fontSize: 16}} />
							</IconButton> */}
							{canAccess(deleteOrderPermission) && <DeleteAlertDialog onDelete={onDeleteOrder} />}
						</Stack>
					</Stack>
					<Card sx={{padding: 4}}>
						<Stack mb={1} direction="row" justifyContent="space-between" alignItems="center">
							{!order.is_cashier &&
								(canUpdateOrderStatus ? (
									<OrderStatusPicker status={order.status} onStatusChange={onStatusChange} />
								) : (
									<Typography>حالة الطلب: {orderStatus[order.status].label}</Typography>
								))}

							{order.is_cashier && order_closed && (
								<Typography py={0.5} px={3} bgcolor={'#E1DADB'} fontSize={18}>
									تم ارجاع الطلب
								</Typography>
							)}

							<Stack spacing={1} direction="row" justifyContent="space-between">
								{canAccess(createOrderPermission) && (
									<Button variant="outlined" onClick={dublicate}>
										تكرار الطلب
									</Button>
								)}
								{canUpdateOrder && !order_closed && (
									<NavButton to={'/orders/update/' + order.uuid} text="تعديل الطلب" />
								)}

								{canUpdateOrder && order.is_cashier && order.status != 'returned' && (
									<>
										<NavButton to={`/orders/${order.uuid}/exchange`} text="تبديل" />
										<Button color="error" variant="outlined" onClick={handleReturnOrder}>
											ارجاع
										</Button>
									</>
								)}
							</Stack>
						</Stack>

						{order.status === orderStatus.cancelled.key && order.reason && (
							<Typography variant="body" color="error">
								سبب الالغاء : {order.reason}
							</Typography>
						)}
						<Divider sx={{mb: 2}} />

						<Stack sx={{mt: 3}} direction={{md: 'row', xs: 'column'}} spacing={2}>
							<Stack flex={1} sx={{minWidth: {md: 450}}} spacing={1.5}>
								{/* 		<Link to={'/orders/update/' + order.uuid} color="inherit" underline="none">
								<Button variant="contained" size="medium">
									تعديل الطلب
								</Button>
							</Link> */}

								<Typography variant="h6">معلومات الزبون</Typography>
								<OrderCustomerDetails order={order} />
								<Typography variant="h6">معلومات اضافية</Typography>
								<OrderAdditionalDetails order={order} />
								{deliveryType !== 'pickup' && (
									<ShippingProvidersActions
										onSendShippmentClicked={onSendShippmentClicked}
										onDeleteShippingClicked={onDeleteShippingClicked}
										order={order}
										setOrder={setOrder}
									/>
								)}
								{accountIs('crocotools', true) && (
									<Button
										variant="outlined"
										startIcon={<img width={60} src={thirdPartyApps.printerco.darkIconPath} />}
										onClick={() => sendOrderToPrinterco(order.id)}
									>
										{'send order to printer'}
									</Button>
								)}
								{canAccess() && !order.is_cashier && (
									<>
										<Typography fontWeight="bold">تعيين مسوؤل عن الطلب</Typography>
										<OrderAssignPicker
											label="المسوؤل"
											defaultValue={order?.assignee?.map((admin) => admin.id) ?? []}
											admins={allAdmins}
											onChange={onAssigneeChange}
										/>
									</>
								)}
							</Stack>

							<OrderDetailsSummury receivePayment={receivePayment} order={order} />
						</Stack>
					</Card>

					{canAccess() && order.is_cashier && (
						<Card sx={{p: 2, mt: 2}}>
							<Stack my={1} spacing={1} direction="row" justifyContent="space-between">
								<Typography variant="h5">الفواتير</Typography>
								{!order.payment_received && order.status != 'returned' && (
									<Button startIcon={<MoneyIcon />} variant="outlined" onClick={() => receivePayment(true)}>
										تأكيد الاستلام
									</Button>
								)}
							</Stack>
							<Stack spacing={1}>
								{order.invoices
									?.map((invoice) => {
										return <InvoiceItem key={invoice.id} invoice={invoice} />;
									})
									.reverse()}
							</Stack>
						</Card>
					)}
					{/* products */}
					<Card sx={{padding: 4, my: 2}}>
						<Stack alignItems="center" spacing={1} direction="row" justifyContent="space-between">
							<Typography sx={{mb: 2}} variant="h5">
								المنتجات
							</Typography>

							{!order.is_cashier && canAccess(updateOrderPermission) && (
								<Stack direction="row">
									{!isNullOrEmpty(order.shippingId) && (
										<InfoTip title="لا يمكن تعديل المنتجات بعد تسجيل الشحنة" />
									)}
									<NavButton
										disabled={!isNullOrEmpty(order.shippingId)}
										text="تعديل"
										to={`/orders/${id}/items/update`}
									/>
								</Stack>
							)}
						</Stack>

						<OrderItemsList
							isCashier={order.is_cashier}
							setItemToDelete={setItemToDelete}
							items={order?.items}
						/>
					</Card>

					{!isNullOrEmpty(order?.delivery_info?.shippingId) && (
						<OrderShippingDetails
							provider={order?.delivery_info?.shippingProvider}
							shippingDataIsLoading={shippingDataIsLoading}
							shipping={shipping}
							logs={shippingTimelines}
						/>
					)}
					<Card sx={{padding: 4, mt: 2}}>
						<Typography variant="h5">ملاحظات</Typography>

						<Stack sx={{mt: 2}}>
							{order.note?.length > 0 ? (
								<Typography variant="body1">{order.note}</Typography>
							) : (
								<Typography variant="body1">لا يوجد ملاحظات</Typography>
							)}
						</Stack>
					</Card>
					{/* <ProductCartWidget /> */}
				</Container>

				{/* this for deleting item from order items */}
				<DeleteWithReasonModal
					title="هل انت متاكد ؟"
					subtitle="ادخل سبب حدف من المنتج"
					isVisible={itemToDelete}
					setVisibility={setItemToDelete}
					handleSubmit={handleDeleteItemSubmit}
				/>
				{/* this for cancelling the order  */}
				<DeleteWithReasonModal
					title="هل انت متاكد ؟"
					subtitle="ادخل سبب الغاء الطلب"
					isVisible={shouldCancelOrder}
					setVisibility={setShouldCancelOrder}
					handleSubmit={handleCancelOrder}
				/>
			</AccessControlWrapper>
		</Page>
	);
}
