import {useQuery} from 'react-query';
import {getShippingProviders} from 'src/utils/AdminApi';

const useShippingProviders = () => {
	return useQuery('getshippingproviders', getShippingProviders, {
		refetchOnWindowFocus: false,
	});
};

export default useShippingProviders;
