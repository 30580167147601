import {Stack, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import * as React from 'react';
import BasicModal from './BasicModal';

export default function DeleteWithReasonModal({
	title,
	subtitle,
	isVisible = false,
	setVisibility,
	handleSubmit,
}) {
	return (
		<>
			<BasicModal
				title={title}
				subtitle={subtitle}
				isVisible={isVisible}
				setVisibility={setVisibility}
			>
				<form onSubmit={handleSubmit}>
					<Stack spacing={3}>
						<TextField
							multiline
							inputProps={{minRows: 3}}
							placeholder="السبب"
							type="text"
							name="reason"
							required
						/>
						{/* <Input type="submit" value="Confirm" /> */}
						<Button variant="contained" type="submit">
							تاكيد
						</Button>
					</Stack>
				</form>
			</BasicModal>
		</>
	);
}
