import {Box, Card, Stack, Typography} from '@mui/material';
import Row from 'src/components/ui/Row';
import AccordionWrapper from 'src/components/wrappers/AccordionWrapper';
import {getSiteCurrency} from 'src/utils/selectors';
import {Invoice} from './types';
import {getUtcDateTimeFromISO} from 'src/utils/date';
import PaidIcon from '@mui/icons-material/Paid';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/AccessTime';
export default function InvoiceItem({invoice, onDelete}: {invoice: Invoice; onDelete: Function}) {
	const currency = getSiteCurrency();
	const {number, type, amount, created_at} = invoice;
	const isPositive = amount >= 0;
	return (
		<Card sx={{p: 2}}>
			<Stack alignItems="center" justifyContent="space-between" direction="row">
				<Stack alignItems="center" direction="row">
					<Box width={30} height={30}>
						{getStatusIcon(invoice)}
					</Box>
					<Stack px={3} width={200}>
						<Typography textAlign="start" fontWeight="bold" style={{fontSize: 16}}>
							{getInvoiceTitle(invoice)}
						</Typography>
						<Typography style={{fontSize: 15, color: 'gray'}}>
							{new Date(created_at).toLocaleString('ar-AE')}
						</Typography>
					</Stack>
				</Stack>
				<Typography
					style={{fontSize: 20, color: !isPositive && '#BE4655', width: 120, paddingInline: 10}}
				>
					{isPositive ? '+' : '-'} {Math.abs(amount)}
					{currency}
				</Typography>
			</Stack>
		</Card>
	);
}

const getStatusIcon = (invoice: Invoice) => {
	if (invoice.cancelled_at) return <CancelIcon color="error" />;
	if (invoice.paid_at) return <PaidIcon sx={{color: '#6FB677'}} />;

	return <PendingIcon sx={{color: '#A9A592'}} />;
};
const getInvoiceTitle = (invoice: Invoice) => {
	const base = `${invoice.number}#`;
	if (invoice.type === 'down_payment') return `${base}-عربون`;
	if (invoice.type === 'order') return `${base}-طلب`;
	if (invoice.type === 'refund') return `${base}-استرجاع`;
	if (invoice.type === 'exchange') return `${base}-تبديل`;

	return invoice.number;
};
