// material
import {Box, Container, Stack, TextField, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import FormikWrapper from 'src/components/wrappers/FormikWrapper';
import LogoutButton from 'src/components/buttons/LogoutButton';
import SubmitButton from 'src/components/buttons/SubmitButton';
import PhoneInput from 'src/PhoneInput';
import {UpdateAdminAccount} from 'src/reducers';
import {requiredEmailValidation, nameValidation, phoneValidation} from 'src/schema/authSchema';
import {handleRequestError} from 'src/utils/helperMethods';
import * as Yup from 'yup';
// components
import Page from '../../components/Page';
import TestPermissionSelector from '../ManageAdminsPage/TestPermissionSelector';
import {accountIs} from 'src/utils/siteUtils';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

const schema = Yup.object().shape({
	name: nameValidation,
	phone: phoneValidation,
	email: requiredEmailValidation,
});

export default function AdminAccount() {
	const user = useSelector((s) => s.admin.user);
	const dispatch = useDispatch();
	const handleSubmit = async (values) => {
		try {
			await dispatch(UpdateAdminAccount({...values, username: values.email}));
			toast.success('تم التحديث بنجاح');
		} catch (error) {
			toast.error(handleRequestError(error));
		}
	};

	return (
		<Page title="حسابي">
			<Container>
				<Typography variant="h4" gutterBottom>
					حسابي
				</Typography>

				<FormikWrapper
					hideSubmitButton
					values={{
						name: user.name,
						phone: user.phone,
						email: user.email,
					}}
					schema={schema}
					handleSubmit={handleSubmit}
				>
					{({isSubmitting, getInputProps}) => (
						<Stack spacing={4}>
							<TextField type="text" label="الاسم" fullWidth {...getInputProps('name')} />

							<PhoneInput label="رقم الهاتف" fullWidth {...getInputProps('phone')} />

							<TextField type="email" label="البريد الالكتروني" fullWidth {...getInputProps('email')} />

							<SubmitButton isSubmitting={isSubmitting}>تحديث المعلومات</SubmitButton>
						</Stack>
					)}
				</FormikWrapper>
				<Stack mt={3} direction="row" justifyContent="space-between" alignItems="center">
					<Link to="/change-password">تغير كلمة المرور</Link>
					{accountIs('demo') && <TestPermissionSelector />}
					<LogoutButton />
				</Stack>
			</Container>
		</Page>
	);
}
