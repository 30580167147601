//mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
//icons
//react-router-dom
//hooks & utils
import {getProducts} from 'src/utils/AdminApi';
//components
import {CircularProgress} from '@mui/material';
import {useState} from 'react';
import {useInfiniteQuery} from 'react-query';
import {getApiSearchQueryString} from 'src/features/productSearch';
import {useDebounce} from 'src/hooks/useDebounce';
import {Product, Variation} from 'src/pages/Orders/types';
import OrderProductsSelectionGrid from 'src/pages/products/common/ProductsGridSelection/ProductsGridSelectionItem';

//icons
//react-router-dom
//hooks & utils
//components
const limit = 20;

/* 
	addedProducts state
*/

type ProductsGridSelectionProps = {
	columns: object;
	handleItemQuantityChange: Function;
	checkAddedItemExists: () => boolean;
	handleAddItem: Function;
	handleRemoveItem: Function;
	addedProducts: (Product | Variation)[];
	resetSelections: Function;
	flexBasis: number;
};
export default function ProductsGridSelection({
	columns,
	handleItemQuantityChange,
	checkAddedItemExists,
	handleAddItem,
	handleRemoveItem,
	addedProducts,
	resetSelections,
	flexBasis,
}: ProductsGridSelectionProps) {
	const gridColumns = {
		xs: 6,
		sm: 4,
		md: 3,
		xl: 2,
		...columns,
	};
	const [searchText, setSearchText] = useState('');
	const delayedSearchTextState = useDebounce(searchText, 500);

	const {
		isLoading = false,
		data,
		fetchNextPage,
		hasNextPage,
		refetch: refetchProducts,
	} = useInfiniteQuery(
		['products', delayedSearchTextState],
		async ({pageParam = 0}) => {
			return getProducts(
				`?_sort=featured:desc,id:desc&_start=${
					pageParam * limit
				}&_limit=${limit}&publish_at_null=true&publish_at_null=false&${getApiSearchQueryString(
					delayedSearchTextState,
				)}`,
			);
		},
		{
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage, allPages) =>
				lastPage.length < limit ? undefined : allPages.length,
		},
	);

	const products = Array.isArray(data?.pages) ? data.pages.flat() : [];

	return (
		<Stack spacing={2} height={'100%'}>
			<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
				<Typography variant="h5">المنتجات</Typography>
				<TextField
					id={'searchInput'}
					placeholder="إبحث عن منتج..."
					variant="outlined"
					size="small"
					color="primary"
					inputProps={{
						style: {
							height: '32px',
							padding: '0px 16px',
						},
					}}
					autoComplete="off"
					sx={{width: '232px'}}
					value={searchText}
					onChange={(e) => {
						const searchTerm = e.target.value?.toLowerCase();
						setSearchText(searchTerm);
					}}
				/>
			</Stack>
			<Divider />
			{/* rememeber you should put grid inside any container like box or stack when you want to play with height in grid */}
			{/* the reason why we put flexBasis = 10px is to make the product scrollable */}
			<Box sx={{minHeight: 500}} flexGrow={1} flexBasis={flexBasis} overflow={'auto'}>
				<Grid container spacing={1}>
					{isLoading && (
						<Stack flex={1} height={500} justifyContent="center" alignItems="center">
							<CircularProgress />
						</Stack>
					)}
					{products.map((product) => {
						return (
							<Grid item {...gridColumns} display={'flex'} justifyContent={'center'} key={product.id}>
								<OrderProductsSelectionGrid
									key={product.id}
									handleRemoveItem={handleRemoveItem}
									checkAddedItemExists={checkAddedItemExists}
									handleAddItem={handleAddItem}
									product={product}
								/>
							</Grid>
						);
					})}
				</Grid>
				{hasNextPage && (
					<Button sx={{mt: 1}} fullWidth onClick={() => fetchNextPage()} variant={'text'}>
						عرض المزيد
					</Button>
				)}
			</Box>
		</Stack>
	);
}
