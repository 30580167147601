import React, {useEffect} from 'react';
import * as serviceWorker from './serviceWorker';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

const ServiceWorkerWrapper = () => {
	const [showReload, setShowReload] = React.useState(false);

	const onSWUpdate = (registration) => {
		setShowReload(true);
	};

	useEffect(() => {
		serviceWorker.register({onUpdate: onSWUpdate});
	}, []);

	const reloadPageClicked = () => {
		setShowReload(false);
		window.location.reload(true);
	};

	return (
		<Snackbar
			open={showReload}
			message="تحديثات جديدة متوفرة !!"
			onClick={reloadPageClicked}
			anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
			action={
				<Button color="primary" variant="contained" size="small" onClick={reloadPageClicked}>
					تحديث
				</Button>
			}
		/>
	);
};

export default ServiceWorkerWrapper;
