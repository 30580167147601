import {Box, Card, Checkbox, Stack, TextField, Typography} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import PhoneInput from 'src/PhoneInput.js';
import {updateAdditionalIfnoSchema} from 'src/schema/updateSiteSchema.js';
import {updateTenantAction} from '../../../reducers/index.js';
import FormikWrapper from '../../wrappers/FormikWrapper.js';
import SubmitButton from '../../buttons/SubmitButton.js';
import TimePicker from '../../inputs/TimePicker.js';

const AdditionalInfoForm = () => {
	const user = useSelector((s) => s.admin.user);
	const site = user.OwnedWebsite;
	const dispatch = useDispatch();
	const handleSubmit = async (values) => {
		const {
			address,
			facebookLink,
			snapchatLink,
			instagramLink,
			start_time,
			close_time,
			storePhone,
			hasPhysicalStore,
			storeEmail,
		} = values;

		toast.promise(
			dispatch(
				updateTenantAction(
					site.id,
					site.site_name,
					{...site?.style},
					{
						...site?.config,
						address,
						start_time,
						close_time,
						storePhone,
						storeEmail,
						facebookLink,
						snapchatLink,
						instagramLink,
						hasPhysicalStore,
					},
					site.logo,
				),
			),
			{
				error: 'حدث خطا غير متوقع',
				success: 'تم التحديث بنجاح',
				pending: 'يرجي الانتظار',
			},
		);
	};

	return (
		<Card sx={{maxWidth: 600, p: 4}}>
			<Stack>
				<Stack
					sx={{maxWidth: 600, mb: 1}}
					direction="row"
					alignItems="center"
					justifyContent="space-between"
				>
					<Typography variant="h4" gutterBottom>
						اعدادات اضافية
					</Typography>
				</Stack>
				<FormikWrapper
					hideSubmitButton
					values={{
						storeEmail: site.config?.storeEmail,
						address: site.config?.address,
						storePhone: site.config?.storePhone,
						facebookLink: site.config?.facebookLink,
						instagramLink: site.config?.instagramLink,
						snapchatLink: site.config?.snapchatLink,
						start_time: site.config?.start_time,
						close_time: site.config?.close_time,
						hasPhysicalStore: site.config?.hasPhysicalStore ?? false,
					}}
					schema={updateAdditionalIfnoSchema}
					handleSubmit={handleSubmit}
				>
					{({isSubmitting, getInputProps, setFieldValue, values}) => (
						<Stack spacing={3}>
							<Typography variant="caption" gutterBottom>
								سيتم استخدام هذه البينات لعرضها في الفوتر الخاص بالموقع
							</Typography>
							<TextField
								label="البريد الالكتروني الخاص بالمحل"
								placeholder="البريد الالكتروني"
								{...getInputProps('storeEmail')}
							/>
							<PhoneInput label=" رقم الهاتف الخاص بالمتجر" fullWidth {...getInputProps('storePhone')} />
							<Typography variant="h4" gutterBottom>
								حسابات مواقع التواصل الاجتماعي
							</Typography>
							<TextField
								label="رابط صفحة الفيسبوك"
								placeholder="مثال https://www.facebook.com/vetrinas.ly"
								{...getInputProps('facebookLink')}
								size="large"
							/>
							<TextField
								label="رابط حساب الانستاقرام"
								placeholder="مثال https://www.instagram.com/vetrinas.ly"
								{...getInputProps('instagramLink')}
								size="large"
							/>
							<TextField
								label="رابط حساب سناب شات"
								placeholder="رابط حساب سناب شات"
								{...getInputProps('snapchatLink')}
								size="large"
							/>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
								}}
							>
								<Typography sx={{flexGrow: 0.95}} variant="h4" gutterBottom>
									هل لديك محل على ارض الواقع
								</Typography>
								<Checkbox
									checked={values.hasPhysicalStore}
									size="medium"
									onChange={(e) => setFieldValue('hasPhysicalStore', e.target.checked)}
								/>
							</Box>
							{values.hasPhysicalStore && (
								<Stack spacing={3}>
									<TextField
										label="عنوان المتجر"
										fullWidth
										placeholder="عنوان المتجر"
										{...getInputProps('address')}
										size="large"
									/>

									<Typography variant="body1" gutterBottom>
										اوقات العمل
									</Typography>

									<Stack spacing={3} justifyContent="space-between" direction="row">
										<TimePicker
											fullWidth
											label="من"
											onChange={(value) => setFieldValue('start_time', value)}
											value={values.start_time}
										/>
										<TimePicker
											fullWidth
											label="إلى"
											onChange={(value) => setFieldValue('close_time', value)}
											value={values.close_time}
										/>
									</Stack>
								</Stack>
							)}
							<SubmitButton isSubmitting={isSubmitting}>تعديل</SubmitButton>
						</Stack>
					)}
				</FormikWrapper>
			</Stack>
		</Card>
	);
};
export default AdditionalInfoForm;
