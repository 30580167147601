import * as React from 'react';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker as MUITimePicker} from '@mui/x-date-pickers/TimePicker';

export default function TimePicker({
	fullWidth = false,
	value,
	label,
	onChange,
}) {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<MUITimePicker
				label={label}
				// value is date object
				value={value}
				onChange={onChange}
				renderInput={(params) => <TextField fullWidth={fullWidth} {...params} />}
			/>
		</LocalizationProvider>
	);
}
