import {Button, IconButton, Stack, Typography} from '@mui/material';
import {toast} from 'react-toastify';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog';
import TableWrapper from 'src/components/wrappers/TableWrapper';
import useVisible from 'src/hooks/useVisible';
import {deleteOrderItems, deletePurchasesItem} from 'src/utils/AdminApi';
import {handleRequestError} from 'src/utils/helperMethods';
import {sumBy} from 'src/utils/numberUtils';
import ArrowUpward from '@mui/icons-material/KeyboardArrowUp';
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import {Link} from 'react-router-dom';

export default function ProductPurchaseItemRecord({items, variationId, quantity, refetch}) {
	const {isVisible, show, hide, toggle} = useVisible();
	const data = variationId ? items.filter((item) => item.variation == variationId) : items;
	const profit = sumBy(data, (item) => (item.type === 'order' ? item.total : -item.total));
	const handleDelete = async (item) => {
		try {
			if (item.type === 'order') {
				const res = await deleteOrderItems(item.id, '');
			} else {
				const res = await deletePurchasesItem(item.id);
			}
			refetch();
		} catch (error) {
			handleRequestError(error, (status, body) => {
				if (status === 406) {
					toast.error(body.message);
				}
			});
		}
	};

	if (data.length === 0) return;
	return (
		<div>
			<Stack spacing={1} direction="row" justifyContent="space-between">
				<IconButton size="small" onClick={toggle}>
					{isVisible ? <ArrowUpward /> : <ArrowLeft />}
					<Typography>سجل الطلبات والمشتريات: {data.length}</Typography>
				</IconButton>
				<Button></Button>
			</Stack>
			{/* <Typography>الطلبات والمشتريات</Typography> */}
			{isVisible && (
				<TableWrapper
					headerColumns={['#', 'الكمية', 'السعر', 'المجموع']}
					dataRows={data.map((item) => [
						<Link
							style={{color: '#1B925F'}}
							to={item.type === 'order' ? `/orders/${item.parent}` : `/purchases/${item.parent}`}
						>
							{item.type === 'order' ? `طلب` : `مشتريات`}
						</Link>,
						item.type === 'order' ? `-${item.quantity}` : `+${item.quantity}`,
						item.price,
						item.total,
						// <DeleteAlertDialog
						// 	disabled={item.type === 'purchase' && quantity - item.quantity < 0}
						// 	key={item.id}
						// 	onDelete={() => handleDelete(item)}
						// />,
					])}
				/>
			)}
		</div>
	);
}
