import PlusFill from '@iconify/icons-eva/plus-fill';
import {Icon} from '@iconify/react';
import {Button, Container, Drawer, Stack, TextField, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog.js';
import FormikWrapper from 'src/components/wrappers/FormikWrapper';
import Page from 'src/components/Page';
import SubmitButton from 'src/components/buttons/SubmitButton';
import TableWrapper from 'src/components/wrappers/TableWrapper';
import {permissionsSelector} from 'src/reducers/app/appSelectors';
import {
	nameValidation,
	requiredEmailValidation,
	requiredPasswordValidation,
} from 'src/schema/authSchema';
import {addAdmin, deleteAdmin, getAdmins, updateAdmin} from 'src/utils/AdminApi';
import {updateArray} from 'src/utils/helperMethods';
import * as Yup from 'yup';
import PermissionsCheckboxList from './PermissionsCheckboxList';
import TestPermissionSelector from './TestPermissionSelector';
import {accountIs} from 'src/utils/siteUtils';

const charactersCount = 8;
const charsRegex = /(?=.*\d).{8,}/gm;
//
const upperCaseRegex = /[A-Z]+/gm;
const numberRegex = /[1-9]+/gm;
const schema = Yup.object().shape({
	name: nameValidation,
	email: requiredEmailValidation,
	password: requiredPasswordValidation,
});
const updateSchema = Yup.object().shape({
	name: nameValidation,
	email: requiredEmailValidation,
});
export default function ManageAdminsPage({user}) {
	const [createDrawerIsVisible, setCreateDrawerVisiblity] = useState(false);
	const [adminToEdit, setAdminToEdit] = useState();
	const dispatch = useDispatch();
	const allPermissions = useSelector(permissionsSelector);
	/**
		const testAdmins = [
			{id: 1, name: 'hatem', email: 'hatem.a.abushaala@gmail.com'},
			{id: 2, name: 'saweyyan', email: 'hatem.a.abushaala@saweyyan.com'},
		];
	*/
	const [admins, setAdmins] = useState([]);
	const addNewAdminDisabled = admins.length >= 2;
	useEffect(() => {
		getAdmins()
			.then((res) => {
				setAdmins(res);
			})
			.catch((err) => alert(err));
	}, []);

	const renderAdmins = () => {
		const rows = admins.map((admin) => [
			admin.name,
			admin.email,
			<Button
				key={admin.id}
				onClick={() => {
					setAdminToEdit(admin);
				}}
				variant="text"
			>
				تعديل
			</Button>,
			<DeleteAlertDialog key={admin.id} onDelete={() => handleDelete(admin.id)} />,
		]);
		return <TableWrapper dataRows={rows} />;
	};

	const handleCreate = async (values, actions) => {
		try {
			// TODO route to add new admin

			const res = await addAdmin(values);
			actions.resetForm();
			setCreateDrawerVisiblity(false);
			setAdmins([...admins, res]);
		} catch (error) {
			//
		}
	};

	const handleUpdate = async (values, actions) => {
		// console.log('valueus', values);
		try {
			// TODO route to update admin

			const res = await updateAdmin(adminToEdit.id, values);
			actions.resetForm();
			setAdminToEdit(null);
			setAdmins(updateArray(admins, res, 'id'));
		} catch (error) {
			alert('something went wrong');
		}
	};

	const handleDelete = async (id) => {
		try {
			// TODO route to delete admin

			await deleteAdmin(id);
			setAdmins(admins.filter((a) => a.id !== id));
		} catch (error) {
			alert('something went wrong');
		}
	};

	return (
		<Page title="الادارة">
			{/* this always return false for non owner users */}
			<AccessControlWrapper controllerAction={'admins'}>
				<Container>
					<Stack sx={{mb: 2}} justifyContent="space-between" direction="row">
						<Typography variant="h4">الحسابات</Typography>

						<Button
							disabled={addNewAdminDisabled}
							onClick={() => {
								setCreateDrawerVisiblity(true);
							}}
							startIcon={<Icon icon={PlusFill} />}
							variant="contained"
						></Button>
					</Stack>
					{/* <Typography>بامكانك اضافة </Typography> */}
					{renderAdmins()}

					{/* Create Admins */}
					<Drawer
						open={createDrawerIsVisible}
						onClose={() => {
							setCreateDrawerVisiblity(false);
						}}
						PaperProps={{
							sx: {
								maxWidth: 500,
								padding: 4,
								border: 'none',
								overflow: 'hidden',
							},
						}}
					>
						<Stack sx={{mb: 3}} direction="row" alignItems="center" justifyContent="space-between">
							<Typography variant="h4">اضافة </Typography>

							<Button
								onClick={() => {
									setCreateDrawerVisiblity(false);
								}}
								variant="outlined"
							>
								x
							</Button>
						</Stack>

						<FormikWrapper
							hideSubmitButton
							schema={schema}
							values={{
								name: '',
								email: '',
								allowed_actions: adminToEdit?.allowed_actions?.map((p) => p.id) ?? [],
							}}
							handleSubmit={handleCreate}
						>
							{({values, setFieldValue, getFieldProps, isSubmitting, getInputProps, ...fkProps}) => (
								<Stack spacing={4}>
									<TextField type="text" label="الاسم" fullWidth {...getInputProps('name')} />
									<TextField type="email" label="البريد الالكتروني" fullWidth {...getInputProps('email')} />

									<TextField
										type="password"
										label="كلمة المرور"
										fullWidth
										error={fkProps.touched.password && fkProps.errors.password}
										{...getFieldProps('password')}
									/>

									<PermissionsCheckboxList
										fieldName="allowed_actions"
										allowed_actions={values.allowed_actions}
										allPermissions={allPermissions}
										setFieldValue={setFieldValue}
									/>

									<SubmitButton isSubmitting={isSubmitting}>اضافة</SubmitButton>
								</Stack>
							)}
						</FormikWrapper>
						{/* <AddCategoryForm
	            onSuccess={(newCategory) => {
	              setCreateDrawerVisiblity(false);
	              setAdmins([...admins, newCategory]);
	            }}
	          /> */}
					</Drawer>
					{/* Update Admin */}
					<Drawer
						open={adminToEdit ? true : false}
						onClose={() => {
							setAdminToEdit(null);
						}}
						PaperProps={{
							sx: {
								maxWidth: 500,
								padding: 4,
								border: 'none',
								overflow: 'hidden',
							},
						}}
					>
						<Stack sx={{mb: 3}} direction="row" alignItems="center" justifyContent="space-between">
							<Typography variant="h4">تعديل </Typography>

							<Button
								onClick={() => {
									setAdminToEdit(null);
								}}
								variant="outlined"
							>
								x
							</Button>
						</Stack>

						<FormikWrapper
							hideSubmitButton
							schema={updateSchema}
							values={{
								// password: '',
								name: adminToEdit?.name ?? '',
								email: adminToEdit?.email ?? '',
								allowed_actions: adminToEdit?.allowed_actions?.map((p) => p.id) ?? [],
							}}
							handleSubmit={handleUpdate}
						>
							{({isSubmitting, getFieldProps, values, getInputProps, setFieldValue}) => {
								return (
									<Stack spacing={4}>
										<TextField type="text" label="الاسم" fullWidth {...getInputProps('name')} />
										<TextField type="email" label="البريد الالكتروني" fullWidth {...getInputProps('email')} />
										{/* <Pass
										wordField {...getFieldProps('password')} label="كلمة المرور" fullWidth /> */}
										<PermissionsCheckboxList
											fieldName="allowed_actions"
											allowed_actions={values.allowed_actions}
											allPermissions={allPermissions}
											setFieldValue={setFieldValue}
										/>
										<SubmitButton isSubmitting={isSubmitting}>تحديث</SubmitButton>
									</Stack>
								);
							}}
						</FormikWrapper>
					</Drawer>
				</Container>
			</AccessControlWrapper>
		</Page>
	);
}
