import {FormControl, FormLabel, RadioGroup} from '@mui/material';
import React from 'react';
import RadioButton from '../inputs/RadioButton';

export default function BasicRadioGroup({label, value, onChange, options, sx = {}}) {
	return (
		<FormControl sx={sx} variant="filled" fullWidth>
			<FormLabel>{label}</FormLabel>
			<RadioGroup value={value} row onChange={onChange}>
				{options.map(({label, value}) => {
					return <RadioButton label={label} value={value} />;
				})}
			</RadioGroup>
		</FormControl>
	);
}
