import {
	Box,
	Card,
	Container,
	Divider,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import PhoneInput from 'src/PhoneInput';
import SubmitButton from 'src/components/buttons/SubmitButton';
import Picker from 'src/components/inputs/Picker';
import Row from 'src/components/ui/Row';
import FormikWrapper from 'src/components/wrappers/FormikWrapper';
import useFind from 'src/hooks/useFind';

import AccessControlWrapper from 'src/components/AccessControlWrapper';
import Page from 'src/components/Page';
import { createOrderPermission, findOneOrderPermission } from 'src/permissionsList';
import { createOrder, getCities } from 'src/utils/AdminApi';
import { getCartItemsTotalPrice, getCartItemsTotalQuantity } from 'src/utils/cartItemUtils';
import { getCity } from 'src/utils/cityUtils';
import {
	getPriceString,
	handleRequestError,
	isNullOrEmpty
} from 'src/utils/helperMethods';
import { buildCartItemFromOrderItem, buildOrderItem } from 'src/utils/orderItemUtils';
import { orderStatusOptions } from 'src/utils/orderUtils';
import { canAccess } from 'src/utils/permissions';
import { getSiteCurrency } from 'src/utils/selectors';
import * as yup from 'yup';
import CartItemSelector from '../CartItemSelector';
import useOrderItemsSelection from '../NewAddOrderPage/useOrderItemsSelection';

const schema = yup.object().shape({
	name: yup.string(),
	// .required('يرجي ادخال اسم الزبون'),
	email: yup.string().email('يرجي ادخال بريد الكتروني صحيح'),
	phone: yup
		.string('')
		// .required('يرجي ادخال رقم الهاتف')
		.min(9, 'رقم الهاتف يجب ان يتكون من ٩ ارقام'),
	address: yup.string(), //.required('يرجي ادخال العنوان'),
	city: yup.string().when('delivery_method', (delivery_method, field) => {
		if (delivery_method === 'delivery') {
			return field.required('يرجي اختيار المدينة');
		} else {
			// no need for validation
			return field;
		}
	}),
	district: yup.string().when('city', (city, field) => {
		const cityHasDistricts = city === 'طرابلس' || city === 'بنغازي';
		if (cityHasDistricts) {
			// district is required when city has districts
			return field.required('يرجي اختيار المنطقة');
		} else {
			// no need for validation
			return field;
		}
	}),
	discount: yup.number().typeError('يرجي ادخال رقم صحيح').min(0, 'قيمة الخصم يجب ان تكون موجبة'),
});

export default function CreateOrder() {
	const navigate = useNavigate();
	const [cities] = useFind(getCities);
	// these variables for inputs coming from dublicate button in order details
	const location = useLocation();
	// we will get order from duplicate button , or product from barcode page
	const {order} = location.state ?? {};

	const {
		handleItemQuantityChange,
		checkAddedItemExists,
		handleAddItem,
		handleRemoveItem,
		addedProducts,
		resetSelections,
	} = useOrderItemsSelection(order?.items?.map(buildCartItemFromOrderItem) ?? []);

	const handleSubmit = async (values) => {
		if (addedProducts.length === 0) {
			return toast.error('يجب اختيار منتج واحد علي الاقل');
		}

		const {
			down_payment,
			city,
			district,
			delivery_method,
			status,
			name,
			email,
			phone,
			address,
			delivery_price,
			note,
			discount,
		} = values;
		const fullAddress = isNullOrEmpty(city) ? address : `${city} ${address}`;
		try {
			const res = await createOrder(
				fullAddress,
				email,
				phone,
				name,
				addedProducts.map(buildOrderItem),
				delivery_price,
				note,
				status,
				{
					type: delivery_method || 'pickup',
					city,
					district,
				},
				discount,
				// down_payment,
			);
			toast.success('تم انشاء الطلب بنجاح');
			if (canAccess(findOneOrderPermission)) navigate('/orders/' + res.uuid);
			else {
				navigate(0);
			}
		} catch (error) {
			handleRequestError(error, (status, errorBody) => {
				if (status === 406) {
					const errorId = errorBody.id;
					if (errorId === 'CART_EMPTY') {
						return toast.error('يجب اختيار منتجات لإدخال الطلب');
					} else if (errorId === 'CART_NOT_VALID') {
						return toast.error('يوجد مشكلة في المنتجات المختارة');
					}
				}
			});
		}
	};

	return (
		<Page title="انشاء طلب">
			<AccessControlWrapper controllerAction={createOrderPermission}>
				<Container maxWidth="md">
					<FormikWrapper
						values={{
							name: order?.customer?.name ?? '',
							email: order?.customer?.email ?? '',
							phone: order?.customer?.phone ?? '',
							address: order?.address ?? '',
							note: order?.note ?? '',
							discount: order?.discount,
							// delivery_price: order?.delivery_price ?? '',
							delivery_method: order?.delivery_info?.type ?? 'pickup',
							city: order?.delivery_info?.city ?? '',
							district: order?.delivery_info?.district ?? '',
						}}
						schema={schema}
						handleSubmit={handleSubmit}
					>
						{({values, isSubmitting, getInputProps}) => {
							const selectedCity = getCity(cities, values.city);
							const cityHasDistricts = !isNullOrEmpty(selectedCity?.districts);
							return (
								<Stack spacing={2}>
									<Typography variant="h3">اضافة طلب</Typography>
									<Card sx={{p: 2}}>
										<CartItemSelector
											title="اختر المنتجات"
											handleItemQuantityChange={handleItemQuantityChange}
											checkAddedItemExists={checkAddedItemExists}
											handleAddItem={handleAddItem}
											handleRemoveItem={handleRemoveItem}
											addedProducts={addedProducts}
											resetSelections={resetSelections}
										/>
										{addedProducts.length > 0 && (
											<Box bgcolor={'#F2F2F2'} mt={1}>
												<Row label="عدد القطع" value={getCartItemsTotalQuantity(addedProducts)} />
												<Divider />
												<Row
													label="مجموع المشتريات"
													value={getPriceString(getCartItemsTotalPrice(addedProducts), getSiteCurrency())}
												/>
											</Box>
										)}
									</Card>

									{addedProducts.length > 0 && (
										<>
											<Card sx={{p: 2}}>
												<Stack spacing={2}>
													<Typography variant="h6">معلومات الزبون</Typography>

													<TextField fullWidth type="text" label="اسم الزبون" {...getInputProps('name')} />
													<PhoneInput
														label="رقم الهاتف"
														placeholder="رقم الهاتف"
														{...getInputProps('phone')}
														fullWidth
														type="tel"
													/>
												</Stack>
											</Card>

											<Card sx={{p: 2}}>
												<Stack spacing={2}>
													<Typography variant="h6">التوصيل والاستلام</Typography>
													<Picker
														options={[
															{id: 'pickup', label: 'استلام شخصي'},
															{id: 'delivery', label: 'توصيل'},
														]}
														label="طريقة الاستلام"
														labelKey="label"
														valueKey="id"
														defaultValue="pickup"
														{...getInputProps('delivery_method')}
													/>

													{values.delivery_method === 'delivery' && (
														<>
															<Picker
																options={cities}
																label="المدينة"
																labelKey="name"
																valueKey="name"
																{...getInputProps('city')}
															/>
															{cityHasDistricts && (
																<Picker
																	options={selectedCity?.districts ?? []}
																	label="المنطقة"
																	labelKey="name"
																	valueKey="name"
																	{...getInputProps('district')}
																/>
															)}
															<TextField
																label="العنوان"
																placeholder="العنوان"
																{...getInputProps('address')}
																fullWidth
																type="text"
															/>
															{/* 		<TextField
														label="سعر التوصيل"
														placeholder="سعر التوصيل"
														{...getInputProps('delivery_price')}
														fullWidth
														type="number"
													/> */}
														</>
													)}
												</Stack>
											</Card>
											<Card sx={{p: 2}}>
												<Stack spacing={3}>
													<Typography variant="h6">معلومات اضافية</Typography>

													{/* 		<TextField
												label="االبريد الالكتروني"
												placeholder="البريد الالكتروني"
												{...getInputProps('email')}
												fullWidth
												type="email"
											/> */}

													<Picker
														options={orderStatusOptions}
														label="حالة الطلب"
														labelKey="label"
														valueKey="key"
														{...getInputProps('status')}
													/>

													<TextField
														label="ملاحظات"
														placeholder="ملاحظات اضافية عن الطلب"
														{...getInputProps('note')}
														fullWidth
														minRows={3}
														multiline
														type="text"
													/>

													{/* <TextField
														fullWidth
														type="text"
														inputMode="numeric"
														pattern="\d*"
														InputProps={{min: 0}}
														label="عربون"
														{...getInputProps('down_payment')}
													/> */}

													<TextField
														fullWidth
														type="text"
														inputMode="numeric"
														pattern="\d*"
														InputProps={{min: 0}}
														label="تخفيض"
														{...getInputProps('discount')}
													/>
													<SubmitButton isSubmitting={isSubmitting}>انشاء</SubmitButton>
												</Stack>
											</Card>
										</>
									)}
								</Stack>
							);
						}}
					</FormikWrapper>
				</Container>
			</AccessControlWrapper>
		</Page>
	);
}
