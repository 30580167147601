import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {useQuery} from 'react-query';
import Picker from 'src/components/inputs/Picker';
import {getAdmins, updateOrder} from 'src/utils/AdminApi';
import {isNullOrEmpty} from 'src/utils/helperMethods';

export const useSiteAdminsQuery = () => {
	return useQuery('getAdmins', () => getAdmins(), {
		// will not try to check for updates until number of ms passed
		staleTime: 1000 * 10,
		// refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
};
export const useOrderAssign = ({orderUUID} = {}) => {
	const {data: allAdmins = []} = useSiteAdminsQuery();
	const [selectedAdmin, setSelectedAdmin] = useState([]); //WE NEED ARRAY OF USER IDS
	const onAssigneeChange = (newAssigns) => {
		setSelectedAdmin(newAssigns);
		updateOrder(orderUUID, {
			assignee: newAssigns,
		});
	};
	return {allAdmins, selectedAdmin, onAssigneeChange};
};
export default function OrderAssignPicker({
	label = 'تعيين',
	size = 'small',
	admins = [],
	onChange,
	value,
	defaultValue = [],
	sx = {},
	noSelectionLabel = 'لا أحد',
}) {
	const onAssigneeChange = (e) => {
		// NOTE remove brackets if you allow multiple assingee
		const newAssigns = isNullOrEmpty(e.target.value) ? [] : [e.target.value];
		if (typeof onChange === 'function') {
			return onChange(newAssigns);
		}
	};
	return (
		<Picker
			// displayEmpty
			sx={sx}
			defaultValue={defaultValue}
			size={size}
			// multiple
			label={label}
			options={[{id: '', name: noSelectionLabel}, ...admins]}
			labelKey="name"
			valueKey="id"
			value={value}
			onChange={onAssigneeChange}
		/>
	);
}
