// material
import {Button, Stack, TextField, Typography} from '@mui/material';
import {useState} from 'react';
// redux
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import PhoneInput from 'src/PhoneInput';
import {RegisterAction} from 'src/reducers';
import {registerSchema} from 'src/schema/authSchema';
import {handleRequestError} from 'src/utils/helperMethods';
import FormikWrapper from '../wrappers/FormikWrapper';
import PasswordField from 'src/components/inputs/PasswordField';
import SubmitButton from '../buttons/SubmitButton';

// ----------------------------------------------------------------------

export default function AdminRegisterForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isSubmitting, setSubmitting] = useState(false);
	const [error, setError] = useState();

	const onSubmit = async (values) => {
		setError(null);
		setSubmitting(true);
		try {
			const {name, phone, email, password} = values;
			await dispatch(RegisterAction(name, phone, email, password));
			navigate('/', {replace: true});
		} catch (err) {
			setSubmitting(false);
			setError(handleRequestError(err));
		}
	};

	return (
		<FormikWrapper
			values={{
				name: '',
				email: '',
				phone: '',
				password: '',
				confirmPassword: '',
			}}
			schema={registerSchema}
			handleSubmit={onSubmit}
		>
			{({getInputProps}) => (
				<Stack>
					<Stack spacing={4}>
						<TextField label="الاسم بالكامل" placeholder="الاسم" {...getInputProps('name')} />

						<TextField
							label="االبريد الالكتروني"
							placeholder="االبريد الالكتروني"
							{...getInputProps('email')}
						/>

						<PhoneInput {...getInputProps('phone')} />

						<PasswordField {...getInputProps('password')} label="كلمة المرور" fullWidth />
						<PasswordField {...getInputProps('confirmPassword')} label="تاكيد كلمة المرور" fullWidth />

						{error && <Typography color="error">{error}</Typography>}

						<SubmitButton isSubmitting={isSubmitting}>متابعة</SubmitButton>

						<Button fullWidth onClick={() => navigate('/login')} size="large" variant="outlined">
							تسجيل الدخول
						</Button>
					</Stack>
				</Stack>
			)}
		</FormikWrapper>
	);
}
