import {Alert, Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {getCountString, isNullOrEmpty} from 'src/utils/helperMethods';
import {redirectToWhatsUp} from 'src/utils/links';
import {daysToExpire} from 'src/utils/relativeDate';

export default function SubscriptionAlert() {
	const {subscription = 'free', expireAt} = useSelector((state) => state.admin.user.OwnedWebsite);
	const isFreeTrial = isNullOrEmpty(subscription) || subscription === 'free';

	// Subscription almost expire
	const subscriptionAlmostExpireTextByType = isFreeTrial
		? 'علي انتهاء فترة اشتراكك التجريبي'
		: 'علي انتهاء اشتراكك';
	const daysToExpiry = daysToExpire(expireAt);
	const arabicFormattedText = getCountString(daysToExpiry, 'يوم', 'يومان', 'ايام');
	const daysToexpirationText = `لقد تبقي ${arabicFormattedText} ${subscriptionAlmostExpireTextByType} `;
	// we muliply by one because the number will be negative

	const callToActionText = isFreeTrial ? 'اشترك الان' : 'تجديد الاشتراك';

	const onClick = () => {
		redirectToWhatsUp();
		// navigate('/payment');
		// window.location = 'https://vetrinas.ly/subscribe.html';
	};

	const ActionButton = () => (
		<Button color="error" onClick={onClick} size="medium" variant="contained">
			{callToActionText}
		</Button>
	);

	// return expireAt;
	if (expireAt && daysToExpiry <= 7) {
		return (
			<Alert sx={{fontSize: 18}} severity="info">
				{daysToexpirationText}
				<ActionButton />
			</Alert>
		);
	} else {
		return null;
	}
}
