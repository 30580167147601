import {Stack} from '@mui/material';
import React, {useState} from 'react';

const Counter = ({
	disabled = false,
	max,
	enableZero,
	step = 1,
	defaultValue = 1,
	onChange,
	handleZero,
}) => {
	const [state, setState] = useState(defaultValue ?? 1);
	const updateValue = (amount) => {
		if (state == 1 && amount == -1 && enableZero == undefined) return handleZero?.call();

		if (max && amount > 0 && state + amount > max) {
			return;
		}
		setState((state) => state + amount);
		if (typeof onChange === 'function') onChange(state + amount);
	};

	const color = '#B6B6B6';

	const btnStyles = {...buttonStyles, color: disabled ? color : 'black'};

	return (
		<Stack
			direction="row"
			alignItems="center"
			// sx={{ opacity: disabled && 0.5 }}
		>
			<button
				type="button"
				style={btnStyles}
				disabled={disabled}
				onClick={() => {
					updateValue(step * -1);
				}}
			>
				-
			</button>
			<p
				style={{
					margin: 12,
					fontSize: 20,
					fontWeight: 'bold',
					color: disabled ? color : 'black',
				}}
			>
				{state ?? 1}
			</p>

			<button
				type="button"
				style={btnStyles}
				disabled={disabled}
				onClick={() => {
					updateValue(step);
				}}
			>
				+
			</button>
		</Stack>
	);
};

const buttonStyles = {
	width: '35px',
	height: '35px',
	fontSize: 20,
	padding: 4,
	fontWeight: 'bold',
	margin: 0,
	background: '#EEEEEE',
	borderRadius: 10,
	color: 'black', // we added this because in ios buttons are blue by default
	borderWidth: 0,
};

export default Counter;
