// material
import GradeIcon from '@mui/icons-material/Grade';
import {Box, Button, Card, IconButton, Link, Stack, Typography} from '@mui/material';
import {experimentalStyled as styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {toast} from 'react-toastify';
import {findOneProductPermission, updateProductPermission} from 'src/permissionsList';
import {updateProduct} from 'src/utils/AdminApi';
import {getImageFormat} from 'src/utils/imageUtils';
import {getProductLink} from 'src/utils/links';
import {canAccess} from 'src/utils/permissions';
import {defaultImageSrc, getProductPrice, getProductQuantity} from 'src/utils/productUtils';
import {Product} from 'vetrina-api-types';
// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
	top: 0,
	width: '100%',
	height: '100%',
	objectFit: 'cover',
	position: 'absolute',
});

// ----------------------------------------------------------------------

ProductCard.propTypes = {
	product: PropTypes.object,
};

const getMainImageUrl = (product) => {
	return (
		getImageFormat(product.main_image, 'thumbnail') ??
		getImageFormat(product.images[0]?.url) ??
		defaultImageSrc
	);
};

function ProductCard({product}: {product: Product}) {
	const [isFeatured, setisFeatured] = useState(product.featured ?? false);
	const {infinite, name} = product;

	const handleToggleFeaturedState = async () => {
		try {
			await updateProduct(product.id, {
				featured: !isFeatured,
			});
			setisFeatured(!isFeatured);
		} catch (err) {
			toast.error('حدث خطأ غير متوقع');
		}
	};
	// const image = main_image?.url ?? images[0]?.url ?? defaultImageSrc;
	const image = getMainImageUrl(product);
	return (
		<Card>
			<Box sx={{pt: '100%', position: 'relative'}}>
				<IconButton
					onClick={handleToggleFeaturedState}
					sx={{
						zIndex: 9,
						top: 16,
						right: 16,
						position: 'absolute',
						textTransform: 'uppercase',
					}}
				>
					<GradeIcon sx={{color: isFeatured ? 'orange' : 'gray'}} />
				</IconButton>
				<ProductImgStyle loading="lazy" alt={name} src={image} />
			</Box>

			<Stack spacing={1} sx={{p: 3}}>
				<Link to={getProductLink(product)} color="inherit" underline="hover" component={RouterLink}>
					<Typography variant="subtitle2" noWrap>
						{name}
					</Typography>
				</Link>

				<Typography variant="body1">السعر {getProductPrice(product)}</Typography>
				{infinite ? (
					<Typography variant="body1">الكمية: يتوفر بالحجز</Typography>
				) : (
					<Typography variant="body1">الكمية {getProductQuantity(product)}</Typography>
				)}
				{/* <Typography variant="subtitle1">
					{category?.name && (
						<Typography variant="body1">
							{`الفئة: ${category?.name || ''}`}
						</Typography>
					)}
				</Typography> */}

				{(canAccess(updateProductPermission) || canAccess(findOneProductPermission)) && (
					<Link to={`/products/${product.id}`} color="inherit" underline="none" component={RouterLink}>
						<Button
							fullWidth
							sx={{color: 'black', p: 1, borderRadius: '0'}}
							variant="contained"
							color="secondary"
						>
							تفاصيل المنتج
						</Button>
					</Link>
				)}
			</Stack>
		</Card>
	);
}
export default ProductCard;
