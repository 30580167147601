export const getRelativeDate = (date, showTime = true) => {
	// Make a fuzzy time
	var delta = Math.round((+new Date() - date) / 1000);

	var minute = 60,
		hour = minute * 60,
		day = hour * 24,
		week = day * 7;

	var fuzzy;
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const daystr = date.getDate();
	let hours = date.getHours();
	let minutes = date.getMinutes();

	const time = getTimeString(hours, minutes);
	if (delta < 30) {
		fuzzy = 'الان';
		// fuzzy = 'just then.';
	} else if (delta < minute) {
		fuzzy = ' منذ ' + delta + ' ثواني ';
		// fuzzy = delta + ' seconds ago.';
	} else if (delta < 2 * minute) {
		// fuzzy = 'a minute ago.';
		fuzzy = 'منذ دقيقة';
	} else if (delta < hour) {
		fuzzy = `منذ ${Math.floor(delta / minute)} دقائق`;
		// fuzzy = Math.floor(delta / minute) + ' minutes ago.';
	} else if (Math.floor(delta / hour) === 1) {
		fuzzy = 'منذ ساعة';
		// fuzzy = '1 hour ago.';
	} else if (delta < day) {
		fuzzy = ' منذ ' + Math.floor(delta / hour) + ' ساعات ';
		// fuzzy = Math.floor(delta / hour) + ' hours ago.';
	} else if (delta < day * 2) {
		fuzzy = 'امس';
		// fuzzy = 'yesterday';
	} else if (delta < week) {
		fuzzy = 'منذ ' + Math.floor(delta / day) + ' ايام ';
		// fuzzy = Math.floor(delta / day) + ' days ago.';
	} else {
		fuzzy = `${daystr}-${month}-${year}` + (showTime ? ` ${time}` : '');
	}
	return fuzzy;
};
export const getShortRelativeDate = (date) => {
	// Make a fuzzy time
	var delta = Math.round((+new Date() - date) / 1000);

	var minute = 60,
		hour = minute * 60,
		day = hour * 24;

	var fuzzy;
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const daystr = date.getDate();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	const time = getTimeString(hours, minutes);

	if (delta < 30) {
		fuzzy = 'الان';
	} else if (delta < minute) {
		fuzzy = ' منذ ' + delta + ' ثواني ';
	} else if (delta < 2 * minute) {
		fuzzy = 'منذ دقيقة';
	} else if (delta < day) {
		fuzzy = time;
	} else if (delta < day * 2) {
		fuzzy = `امس ${time}`;
	} else {
		fuzzy = `${daystr}-${month}-${year} ${time}`;
	}
	return fuzzy;
};

const leadingZero = (number) => {
	if (number < 10) return `0${number}`;

	return number;
};

const getTimeString = (hours, minutes) => {
	return `${leadingZero(hours)}:${leadingZero(minutes)}`;
};

export const daysToExpire = (dateString) => {
	if (!dateString || typeof dateString !== 'string' || dateString.length === 0)
		return 0;
	// convert date coming from databse to date object
	let date;
	// safety check for bad date strings format
	try {
		date = new Date(dateString);
	} catch (error) {
		return 0;
	}
	// get time in milliseconds
	const timestamp = date.getTime();
	// compare difference between current time and expireation date
	const diff = timestamp - Date.now();
	// calculate milliseconds in 1 day
	const oneDay = 1000 * 60 * 60 * 24;

	// divide time difference to get number of days
	const daysRemains = Math.ceil(diff / oneDay);
	return daysRemains;
};

daysToExpire('2022-03-22');
