import {Stack, Switch, Typography} from '@mui/material';
import InfoTip from 'src/components/ui/InfoTip';
export default function PublishSwitch({sx = {}, value, onChange}) {
	return (
		<Stack sx={sx} alignItems="center" direction="row" spacing={1}>
			<Typography>معروض في المتجر</Typography>
			<InfoTip title="سيتم عرض المنتج في المتجر إذا تم تفعيل هذا الخيار" />
			<Switch
				checked={value}
				onChange={(e) => {
					if (e.target.checked) {
						onChange(new Date());
					} else {
						onChange(null);
					}
				}}
			/>
		</Stack>
	);
}
