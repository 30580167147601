import {Button, Divider, Stack, TextField, Typography} from '@mui/material';
import {updateArray, uuidv4} from 'src/utils/helperMethods';

export default function CustomFields({
	value = [],
	onChange,
	errors = [],
	touches = [],
	setFieldTouched,
}) {
	const addNewSpecification = (e) => {
		e.preventDefault();
		// setSpecifications([...specifications, {id: uuidv4(), name: '', value: ''}]);
		onChange([...value, {id: uuidv4(), name: '', value: ''}]);
	};

	const removeSpecification = (id, e) => {
		e.preventDefault();
		// setSpecifications(specifications.filter((s) => s.id !== id));
		onChange(value.filter((s) => s.id !== id));
	};

	const onSpecificationChange = (spec) => {
		onChange(updateArray(value, spec, 'id'));
	};

	return (
		<Stack spacing={2}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography variant="body1">جدول المواصفات </Typography>
				<Button variant="text" type="button" onClick={addNewSpecification}>
					إضافة
				</Button>
			</Stack>
			<Divider />

			{value.map((s, i) => (
				<Stack key={i} spacing={4} direction="row">
					<TextField
						fullWidth
						type="text"
						label="الوصف"
						required
						value={s.name}
						error={Boolean(touches[i]?.name && errors[i]?.name)}
						onChange={(e) => onSpecificationChange({...s, name: e.target.value})}
						onBlur={() => {
							setFieldTouched('attrs', {...touches, [i]: {...touches[i], name: true}});
						}}
					/>

					<TextField
						fullWidth
						type="text"
						label="القيمة"
						value={s.value}
						required
						error={Boolean(touches[i]?.value && errors[i]?.value)}
						onBlur={() => {
							setFieldTouched('attrs', {
								...touches,
								[i]: {...touches[i], value: true},
							});
						}}
						onChange={(e) => onSpecificationChange({...s, value: e.target.value})}
					/>

					<Button size="small" variant="outlined" onClick={(e) => removeSpecification(s.id, e)}>
						-
					</Button>
				</Stack>
			))}
		</Stack>
	);
}
