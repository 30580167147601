import DeleteIcon from '@mui/icons-material/Delete';
import {Divider, IconButton, Stack} from '@mui/material';
import {updateOrderPermission} from 'src/permissionsList';
import {canAccess} from 'src/utils/permissions';
import {OrderProductItem} from './OrderItem.item';

export function OrderItemsList({setItemToDelete, isCashier, items = [], ...props}) {
	return (
		<Stack direction="column" spacing={2} {...props}>
			{items
				// .filter((i) => i.isDeleted !== true)
				.map((item, index) => (
					// <Grid key={item.id} item xs={6} md={4} >
					<div key={item.id}>
						{/* {item.id} */}
						<Stack
							sx={{opacity: item.isDeleted && 0.4}}
							alignItems="center"
							direction="row"
							justifyContent="space-between"
						>
							<OrderProductItem item={item} />
							{!isCashier &&
								(!item.isDeleted
									? canAccess(updateOrderPermission) && (
											<IconButton size="small" sx={{opacity: 0.5}} onClick={() => setItemToDelete(item.id)}>
												<DeleteIcon />
											</IconButton>
									  )
									: 'محذوف')}
						</Stack>

						{items.length - 1 < index && <Divider />}
						{/* {item.reason} */}
					</div>
				))}
		</Stack>
	);
}
