import {FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack} from '@mui/material';
import React from 'react';

export default function Picker({
	size,
	options,
	name,
	label,
	helperText,
	error,
	valueKey,
	labelKey,
	onChange,
	value,
	defaultValue,
	fullWidth = false,
	disabled = false,
	displayEmpty = false,
	multiple = false,
	renderMenuItem,
	sx = {},
	renderValue,
}) {
	return (
		<FormControl size={size} sx={sx} fullWidth={fullWidth}>
			<InputLabel id="demo-simple-select-error-label">{label}</InputLabel>

			<Select
				disabled={disabled}
				// displayEmpty={true}
				multiple={multiple}
				defaultValue={defaultValue}
				error={error}
				name={name}
				// labelId="demo-simple-select-error-label"
				// id="demo-simple-select-error"
				// native
				value={value}
				onChange={onChange}
				label={label}
				MenuProps={{
					PaperProps: {sx: {maxHeight: 300}},
				}}
				//   inputProps={{
				//     name: 'age',
				//     id: 'outlined-age-native-simple',
				//   }}
			>
				{/* <MenuItem aria-label="None" value="">
          {label}
        </MenuItem> */}
				{options.map((option) => (
					<MenuItem key={option[valueKey] ?? option} value={option[valueKey] ?? option}>
						{typeof renderMenuItem === 'function' ? renderMenuItem(option) : option[labelKey] ?? option}
					</MenuItem>
				))}
			</Select>
			{error && <FormHelperText error>{helperText}</FormHelperText>}
		</FormControl>
	);
}
