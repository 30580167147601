import {SHOW_MODAL, HIDE_MODAL} from './modalActionsTypes';

export const hideModalAction = {
	type: HIDE_MODAL,
};

export const showModalAction = (title, body, actionButtonText = 'تمام', redirectTo = null) => {
	return {
		type: SHOW_MODAL,
		payload: {
			title,
			body,
			actionButtonText,
			redirectTo,
		},
	};
};
