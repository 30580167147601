import NotificationsIcon from '@mui/icons-material/Notifications';
import {Badge, Box, Stack, Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {APPBAR_DESKTOP, APPBAR_MOBILE} from 'src/constants.js';
import useOutsideAlerter from 'src/hooks/useOutsideAlerter.js';
import store from '../../../redux-store.js';
import NotificationDropMenu from './NotificationsDropMenu.js';
import NotificationMenu from './NotificationMenu.js';
import {useQuery} from 'react-query';
import {getNotifications, setAllNotificationSeen} from 'src/utils/AdminApi.js';

const NOTIFICATION_DROP_MENU_STYLES = {
	position: {xs: 'fixed', md: 'fixed'},
	// insetInlineEnd: {xs: -114, sm: -90, lg: -100},
	// insetBlockStart: {xs: 11, sm: 11, lg: 25},
	right: {xs: 0, lg: 50},
	top: '100%',
	// top: {xs: APPBAR_MOBILE, lg: APPBAR_DESKTOP},
	width: {xs: '100vw', lg: 450},
	// height: {xs: '100vh', md: 400},
	height: {lg: 400},
	zIndex: '322561651',
	backdropFilter: 'blur(10px)',
	// bgcolor: '#fffff2',
	bgcolor: '#ffffff',
};

const NotificationWidget = ({pthNameObj}) => {
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, () => {
		document.body.style.overflow = 'auto';
		setMenuDisplay(false);
	});
	const location = useLocation();
	const [isMenuShown, setMenuDisplay] = useState(false);

	const {data: notifications = [], refetch: refetchNotifications} = useQuery(
		'notifications',
		getNotifications,
		{
			// cacheTime: 100000000,
			// staleTime: 100000000,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);

	const navigate = useNavigate();

	const navigateToNotifications = async (e) => {
		await toggleDropMenuDisplay(e);
		navigate('/notifications');
	};

	useEffect(() => {
		// setMenuDisplay(isMobWidgetShown() ? true : false);
	}, [location]);

	const navigateToNotification = async (e, id, type) => {
		await toggleDropMenuDisplay(e);
		if (type === 'suggestion') {
			return navigate('suggestions', {replace: true});
		}
		// return navigate('/notification/' + id, {keepPreviousData: true});
	};

	function areAllSeen() {
		return findUnseenNotificationsCount() === 0;
	}

	const toggleDropMenuDisplay = async (e) => {
		// e.preventDefault();
		// pthNameObj?.changePathName(location.pathname)

		if (!areAllSeen()) {
			await setAllNotificationSeen();
			refetchNotifications();
		}
		setMenuDisplay((prevState) => {
			document.body.style.overflow = prevState ? 'auto' : 'hidden';
			return !prevState;
		});
		// checkForMobileNavigation(location, navigate);
	};

	const allProps = {
		notificationsIcon: {
			sx: {fontSize: 25, color: !isMenuShown ? 'white' : 'none'},
			color: isMenuShown ? 'secondary' : 'primary',
		},
		dropMenu: {
			NOTIFICATION_DROP_MENU_STYLES,
			styles,
			goToNotifications: (e) => navigateToNotifications(e),
		},
	};

	function findUnseenNotificationsCount() {
		// return 66;
		return notifications?.filter((notification) => !notification.seen).length ?? 0;
	}

	return (
		<Box ref={wrapperRef} sx={{position: 'relative'}}>
			<IconButton onClick={toggleDropMenuDisplay}>
				<Badge badgeContent={findUnseenNotificationsCount()} color="secondary">
					<NotificationsIcon {...allProps.notificationsIcon} />
				</Badge>
			</IconButton>

			<NotificationMenu
				menuShown={isMenuShown}
				notifications={notifications}
				goToAllNotifications={navigateToNotifications}
				goToNotification={navigateToNotification}
			/>
		</Box>
	);
};

export default NotificationWidget;

const styles = {
	gridContainer: {
		height: '100%',
		width: '100%',
		overflow: '-moz-scrollbars-none;',
		overflowY: 'auto',
		msOverflowStyle: 'none',
		scrollbarWidth: 0,
		borderInline: '1px outset black',
	},
	gridItem: {
		p: 2,
		color: 'black',
		cursor: 'pointer',
	},
	widgetBtnContainer: {
		bgcolor: 'inherit',
		width: '100%',
		display: 'grid',
		justifyContent: 'center',
		borderRadius: '0 0 15px 15px',
		border: '1px outset black',
		borderTop: 'none',
		boxShadow: '0px 8px 15px -3px rgba(0,0,0,0.41)',
		webkitBoxShadow: '0px 8px 15px -3px rgba(0,0,0,0.41)',
		mozBoxShadow: '0px 8px 15px -3px rgba(0,0,0,0.41)',
		backdropFilter: 'blur(10px)',
	},
};
