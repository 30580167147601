import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import {getNumber, sumBy} from './numberUtils';

export const getItemTotalPrice = (price, discount, quantity) => {
	return calcPriceAfterDiscount(price, discount) * quantity;
};
// used by cashier and productgridselection to return order item object based on product or variation
export const buildOrderItem = (item) => {
	const {price, discount, selectedQuantity} = item;
	return {
		price,
		discount,
		product: item.product,
		variation: item.variation,
		quantity: getNumber(selectedQuantity, 1),
	};
};
// related to src/pages/products/common/ProductsGridSelection/ProductsGridSelectionItem.jsx addItem method
export const buildCartItemFromOrderItem = (item) => {
	let isVariation = item.variation != null;

	return {
		// copy product meta attributes like name
		...item.product,
		...item,
		quantity: isVariation ? item.variation.quantity : item.product.quantity,
		selectedQuantity: item.quantity,
		product: item.product.id,
		variation: item.variation?.id,
		id: isVariation
			? `variation-${item.variation?.id ?? item.variation}`
			: item.product?.id ?? item.product,
		isVariation,
	};
};
