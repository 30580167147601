import {Box, Container, Fab, styled} from '@mui/material';
import {DRAWER_WIDTH} from 'src/constants';
const RootStyle = styled(Box)(({theme}) => ({
	width: '100%',
	left: 0,
	bottom: 0,
	zIndex: 100000,
	padding: '15px 10px',
	// background: 'rgba(0,0,0,0.75)',
	borderTop: 'solid 1px rgba(0,0,0,0.15)',
	background: 'rgba(255,255,255,0.85)',
	[theme.breakpoints.up('lg')]: {
		width: `calc(100% - ${DRAWER_WIDTH}px)`,
		left: DRAWER_WIDTH,
	},
}));

export default function FabSaveButton({
	position = 'fixed',
	type = 'button',
	onClick,
	left,
	top,
	right = 40,
	bottom = 80,
}) {
	return (
		<RootStyle
			sx={{
				position,
				// right,
				// bottom,
				// left,
				// top,
			}}
		>
			<Container>
				<Fab
					onClick={onClick}
					// sx={{position, right, bottom, left, top}}
					variant="extended"
					color="primary"
					aria-label="add"
					type={type}
				>
					حفظ التغيرات
				</Fab>
			</Container>
		</RootStyle>
	);
}
