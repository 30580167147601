import {useDispatch, useSelector} from 'react-redux';
import {
	ADD_CATEGORY,
	DELETE_CATEGORY,
	SET_CATEGORIES,
	UPDATE_CATEGORY,
} from 'src/reducers/category/categoryReducer';
import {getCategories} from 'src/utils/AdminApi';

export default function useCategories() {
	const dispatch = useDispatch();
	const categories = useSelector((s) => s.category.data);

	const fetchCategories = async () => {
		try {
			const response = await getCategories();
			dispatch({
				type: SET_CATEGORIES,
				payload: {data: response},
			});
		} catch (err) {
			// handle error
		}
	};

	const updateCategory = (category) =>
		dispatch({
			type: UPDATE_CATEGORY,
			payload: {category},
		});

	const setCategories = (data) =>
		dispatch({
			type: SET_CATEGORIES,
			payload: {data},
		});

	const deleteCategory = (id) =>
		dispatch({
			type: DELETE_CATEGORY,
			payload: {id},
		});

	const addCategory = (category) => {
		dispatch({
			type: ADD_CATEGORY,
			payload: {category},
		});
	};
	/* const [categories, setCategories] = useState([]);


	useEffect(() => {
		fetchCategories();
	}, []);
	*/
	return {categories, fetchCategories, updateCategory, setCategories, addCategory, deleteCategory};
}
