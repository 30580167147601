import {Button, Checkbox, FormControlLabel, Stack, TextField} from '@mui/material';
import {Form, Formik, FormikHelpers, FormikProps} from 'formik';
import React from 'react';
import PhoneInput from 'src/PhoneInput';
import {mapToObject} from 'src/utils/array';
import {object} from 'yup';
import ImageCropPicker from '../inputs/ImageCropPicker.js/ImageCropPicker';
import PasswordField from 'src/components/inputs/PasswordField';
import Picker from 'src/components/inputs/Picker';
import {DynamicFormConfig} from './DynamicForm.types';

const defaultOnSubmit = (v) =>
	console.log('you forget to pass onSubmit prop, these values to be submitted : ', v);

export default function DynamicForm<Values>(props: {
	// important props
	config: DynamicFormConfig<Values>;
	initialValues: Values;
	onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void | Promise<any>;
	// customization
	showSubmitButton: boolean;
	spacing?: number;
	submitButtonText: string;
	// for edge cases, for example when you need to add custom inputs with different layout in the end
	children?: ((props: FormikProps<Values>) => React.ReactNode) | React.ReactNode;
}) {
	const {
		config,
		spacing = 2,
		initialValues = {},
		onSubmit,
		submitButtonText = 'انشاء',
		//
		showSubmitButton = true,
		children,
	} = props ?? {};

	const getInputProps =
		({getFieldProps, ...formikProps}) =>
		(inputName) => {
			// for material ui extra props to handle the error text and visiblity
			const helperProps = {
				onWheel: (e) => e.target.blur(),
				error: Boolean(formikProps.touched[inputName] && formikProps.errors[inputName]),
				helperText: formikProps.touched[inputName] && formikProps.errors[inputName],
			};

			return {...helperProps, ...getFieldProps(inputName)};
		};

	const renderInputs = (formikProps) => {
		// const inputProps = getInputProps(formikProps);

		return (
			<Stack spacing={spacing}>
				{config.inputs.map((input) => {
					const commonProps = {
						label: input.label,
						type: input.type,
						placeholder: input.placeholder,
						...getInputProps(formikProps)(input.name),
					};
					// render component based on type

					if (typeof input.component === 'function') {
						return <input.component key={input.name} formik={formikProps} />;
					}

					if (input.type.match(/^(text|email|number)$/))
						return (
							<TextField
								minRows={input.rows}
								multiline={input.multiline}
								{...commonProps}
								helperText={input.helperText ?? commonProps?.helperText}
							/>
						);
					if (input.type === 'tel') return <PhoneInput {...commonProps} />;
					if (input.type === 'password') return <PasswordField {...commonProps} />;
					if (input.type === 'image-crop')
						return (
							<ImageCropPicker
								ratio={input.ratio}
								label={input.label}
								setFile={(file) => formikProps.setFieldValue(input.name, file)}
								{...getInputProps(formikProps)(input.name)}
							/>
						);
					if (input.type === 'file')
						return (
							<input
								type="file"
								// {...commonProps}
								onChange={(e) => {
									e.preventDefault();
									formikProps.setFieldValue(input.name, e.target.files[0]);
								}}
							/>
						);

					if (input.type === 'picker') {
						return (
							<Picker
								key={input.name}
								options={input.options}
								{...commonProps}
								valueKey={input.valueKey}
								labelKey={input.labelKey}
								onChange={(e) => formikProps.setFieldValue(input.name, e.target.value)}
							/>
						);
					}
					if (input.type === 'checkbox') {
						return (
							<FormControlLabel
								key={input.name}
								control={
									<Checkbox
										{...commonProps}
										defaultChecked={input.value}
										// name={input.name}
										// checked={input.value}
										// onChange={(e) => formikProps.setFieldValue(input.name, e.target.value)}
									/>
								}
								label={input.label}
							/>
						);
					}
					if (input.type === 'select')
						return (
							<select
								name={input.name}
								onChange={(e) => {
									formikProps.setFieldValue(input.name, e.target.value);
								}}
							>
								<option>{input.placeholder}</option>
								{input.options.map((option, index) => (
									<option key={index} value={option[input.valueKey] ?? option}>
										{' '}
										{option[input.labelKey] ?? option}
									</option>
								))}
							</select>
						);
					// else return <input {...commonProps} />;
				})}

				{typeof children === 'function' &&
					children({
						...formikProps,
						getInputProps: getInputProps(formikProps),
					})}
			</Stack>
		);
	};

	const renderChild = (formikProps) => {
		return (
			<Form autoComplete="off" onSubmit={formikProps.handleSubmit}>
				{renderInputs(formikProps)}

				{showSubmitButton && (
					<Button
						disabled={formikProps.isSubmitting}
						sx={{mt: 2}}
						fullWidth
						variant="contained"
						type="submit"
					>
						{submitButtonText}
					</Button>
				)}
				{/* {showSubmitButton &&
					(formikProps.isSubmitting ? (
						'loading'
					) : (
						<input style={{marginTop: 12}} type="submit" value="Submit" />
					))} */}
			</Form>
		);
	};

	// this to make the state consistant with inputs
	// so that we don't get values not exist in inputs array
	/**
	 * inital valeus : {test:'',email:'',password:''}
	 * config : {inputs:[{name:'email'}]}
	 *
	 * then it should return email only
	 * {email:''}
	 */
	const getInitalValues = () => {
		if (!config || !config.inputs || !Array.isArray(config.inputs)) return {};

		const valuesObject = mapToObject(config.inputs, (input) => ({
			[input.name]: initialValues[input.name] ?? input.value,
		}));
		return valuesObject;
	};

	const getValidationSchema = () => {
		if (!config || !config.inputs || !Array.isArray(config.inputs)) return null;

		const schemaConfigObject = mapToObject(config.inputs, (input) => ({
			[input.name]: input.validation,
		}));

		return object(schemaConfigObject);
	};

	return (
		<Formik
			enableReinitialize
			onSubmit={onSubmit ?? config.onSubmit ?? defaultOnSubmit}
			initialValues={getInitalValues()}
			validationSchema={getValidationSchema}
			// eslint-disable-next-line react/no-children-prop
			children={renderChild}
		/>
	);
}

// const configExample = {
// 	inputs: [
// 		{
// 			name: 'name',
// 			label: 'name label',
// 			placeholder: 'name placeholder',
// 			type: 'text',
// 			validation: nameValidation,
// 			value: '',
// 		},
// 		{
// 			name: 'email',
// 			label: 'email label',
// 			placeholder: 'email placeholder',
// 			type: 'email',
// 			validation: emailValidation,
// 			value: '',
// 		},
// 		{
// 			name: 'password',
// 			label: 'password label',
// 			placeholder: 'password placeholder',
// 			type: 'password',
// 			validation: passwordValidation,
// 			value: '',
// 		},
// 		{
// 			name: 'phone',
// 			label: 'phone label',
// 			placeholder: 'phone placeholder',
// 			type: 'tel',
// 			validation: phoneValidation,
// 			value: '',
// 		},
// 		{
// 			name: 'image',
// 			label: 'image label',
// 			placeholder: 'image placeholder',
// 			type: 'image-crop',
// 			value: '',
// 		},
// 	],

// 	onSubmit: (values, actions) => {
// 		createCategory(values.image);
// 	},
// };

// construct configs from state
/**
 * 	const create = (data) => {
		if (!data) return configExample;

		return {
			...configExample,
			inputs: configExample.inputs.map((input) => ({
				...input,
				value: data[input.name],
			})),
		};
	};
 */

/**
 * 
 * dyanmic select with custom component
 * 
 * {
			name: 'select',
			label: 'select label',
			placeholder: 'select placeholder',
			type: 'select',
			component: function First({formik}) {
				const categories = useCategories([]);

				return (
					<Picker
						label="الفئة"
						options={categories}
						labelKey="name"
						valueKey="id"
						onChange={(e) => formik.setFieldValue('category', e.target.value)}
					/>
				);
			},
			value: '',
		},
 */
