import {Button, CircularProgress} from '@mui/material';
import React from 'react';

export default function SubmitButton({
	fullWidth = true,
	isSubmitting,
	disabled,
	sx = {},
	size = 'large',
	...props
}) {
	if (isSubmitting) return <CircularProgress color="secondary" />;
	else
		return (
			<Button
				sx={{mt: 4, ...sx}}
				fullWidth={fullWidth}
				size={size}
				type="submit"
				variant="contained"
				color="secondary"
				disabled={disabled}
			>
				{props.children}
			</Button>
		);
}
