// material
import {Stack, Container, Typography} from '@mui/material';
// components
import Page from '../../components/Page';
import FormikWrapper from 'src/components/wrappers/FormikWrapper';
import SubmitButton from 'src/components/buttons/SubmitButton';
import {toast} from 'react-toastify';
import * as yup from 'yup';
import {changePassword} from 'src/utils/AdminApi';
import PasswordField from 'src/components/inputs/PasswordField';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

const schema = yup.object({
	oldPassword: yup
		.string()
		// .min(8, 'كلمة السر القديمة غير صحيحة')
		.required(' يرجى ادخال  كلمة السر القديمة '),
	password: yup
		.string()
		// .min(8, 'ادخل كلمة السر اكثر من 8 حروف')
		.required(' يرجى ادخال  كلمة السر الجديدة '),
	confirmPassword: yup
		.string()
		.required('يرجى تأكيد كلمة السر الجديدة ')
		.test('confirm-password-test', 'كلمة السر غير متطابقة', function (value) {
			return value === this.parent.password;
		}),
});

export default function AdminAccount() {
	const handleSubmit = async (values) => {
		const {oldPassword, password} = values;
		try {
			await changePassword(oldPassword, password);
			toast.success('تم التحديث بنجاح');
		} catch (error) {
			if (error.response.status === 400) toast.error('كلمة المرور الحالية غير صحيحة');
			else toast.error('حدث خطا غير متوقع');
		}
	};

	return (
		<Page title="تغير كلمة المرور">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
					<Typography variant="h4" gutterBottom>
						تغير كلمة المرور
					</Typography>
				</Stack>

				<FormikWrapper
					hideSubmitButton
					values={{
						oldPassword: '',
						password: '',
						confirmPassword: '',
					}}
					schema={schema}
					handleSubmit={handleSubmit}
				>
					{({isSubmitting, getInputProps}) => (
						<Stack spacing={4}>
							<PasswordField
								type="text"
								label={'كلمة المرور الحالية'}
								fullWidth
								{...getInputProps('oldPassword')}
							/>

							<PasswordField
								type="text"
								label={'كلمة المرور الجديدة'}
								fullWidth
								{...getInputProps('password')}
							/>

							<PasswordField
								type="text"
								label={'تأكيد كلمة المرور '}
								fullWidth
								{...getInputProps('confirmPassword')}
							/>

							<SubmitButton isSubmitting={isSubmitting}>تحديث </SubmitButton>
						</Stack>
					)}
				</FormikWrapper>
			</Container>
		</Page>
	);
}
