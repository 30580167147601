import {useState} from 'react';

export default function useMultipleChanges(key = 'id') {
	const [changes, setChanges] = useState({});

	// useEffect(() => {}, [changes]);
	const resetProperty = (id, property) => {
		const newChanges = {...changes};
		delete newChanges[id]?.[property];
		if (Object.keys(newChanges).length <= 1) delete newChanges[id];
		setChanges(() => newChanges);
	};
	const clearItemChanges = (id) => {
		const newChanges = {...changes};
		delete newChanges[id];
		setChanges(() => newChanges);
	};

	const handleValueChange = (newValue, row, property) => {
		if (newValue != row[property])
			// row[key] equivilent to row.id but we need the key to be dynamic
			setChanges((changes) => ({
				...changes,
				[row[key]]: {...changes[row[key]], [property]: newValue},
			}));
		else {
			resetProperty(row[key], property);
		}
	};
	// const handleRowChange = (newValue, row, property) => {
	// 	if (newValue != row[property])
	// 		// row[key] equivilent to row.id but we need the key to be dynamic
	// 		setChanges((changes) => ({
	// 			...changes,
	// 			[row[key]]: {...row, ...changes[row[key]], [property]: newValue},
	// 		}));
	// 	else {
	// 		resetProperty(row[key], property);
	// 	}
	// };

	return {
		changes,
		resetProperty,
		setChanges,
		handleValueChange,
		clearItemChanges,
	};
}
