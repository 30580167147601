// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// css
import './App.css';
// components
import {Provider} from 'react-redux';
// Create rtl cache
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from 'src/redux-store';
import rtlPlugin from 'stylis-plugin-rtl';
import {QueryClient, QueryClientProvider} from 'react-query';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';

const cacheRtl = createCache({
	key: 'muirtl',
	stylisPlugins: [rtlPlugin],
});

function RTL({children}) {
	return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
}
////////////////////////////////////////

const reactQueryClient = new QueryClient();
export default function App() {
	return (
		<QueryClientProvider client={reactQueryClient}>
			<Provider store={store}>
				{/* <StylesProvider jss = {jss} > */}
				<ThemeConfig>
					<RTL>
						<ToastContainer
							position="bottom-center"
							autoClose={2000}
							hideProgressBar
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						<Router />
						<ServiceWorkerWrapper />
					</RTL>
				</ThemeConfig>
				{/* </StylesProvider> */}
			</Provider>
		</QueryClientProvider>
	);
}
