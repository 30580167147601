//mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
//icons
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
//react-router-dom
import {Link, useLocation, useNavigate} from 'react-router-dom';
//hooks & utils
import {toast} from 'react-toastify';
import {createOrder, getOrder} from 'src/utils/AdminApi';
import {
	buildCartItemFromOrderItem,
	buildOrderItem,
	getItemTotalPrice,
} from 'src/utils/orderItemUtils';
import {getNumber, sumBy} from '../../../utils/numberUtils';
//components
import {useEffect, useState} from 'react';
import {printJsonReceipt} from 'src/features/printer';
import ProductsGridSelection from 'src/pages/products/common/ProductsGridSelection/ProductsGridSelection';
import {findOrderPermission} from 'src/permissionsList';
import {handleRequestError, isNullOrEmpty} from 'src/utils/helperMethods';
import {canAccess} from 'src/utils/permissions';
import {accountIs} from 'src/utils/siteUtils';
import SelectedOrderItems from './SelectedOrderItems';
import useOrderItemsSelection from './useOrderItemsSelection';
import Page from 'src/components/Page';
import {getCartItemsTotalPrice} from 'src/utils/cartItemUtils';
import {InputAdornment} from '@mui/material';
import CartItem from '../CartItem.item';

export default function CashierPage() {
	const [downPayment, setDownPayment] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const navigate = useNavigate();

	// these variables for inputs coming from dublicate button in order details
	const location = useLocation();
	// we will get order from duplicate button
	const {order} = location.state ?? {};

	const {
		handleItemQuantityChange,
		checkAddedItemExists,
		handleAddItem,
		handleRemoveItem,
		addedProducts,
		resetSelections,
	} = useOrderItemsSelection(order?.items?.map(buildCartItemFromOrderItem) ?? []);

	const handleBuyButton = async (gateway = 'cash') => {
		let phone;
		if (gateway == 't_lync') {
			phone = prompt('ادخل رقم الهاتف', '0924474423');
			if (!phone) return;
		}
		setIsSubmitting(true);
		// transform added products state to array accepted by the api
		const cart = addedProducts.map(buildOrderItem);
		try {
			const res = await createOrder(
				'', // address
				'', // email
				phone ?? '', // phone
				'', //name
				cart,
				0, //delivery_price
				'', //note
				'delivered', //status
				{
					type: 'pickup',
				}, // delivery info
				0, //discount
				getNumber(downPayment), //down payment
				getNumber(downPayment) === 0, // set payment received to true if no down payment
				true, // is_cashier
				{
					// payment info: default is cash
					gateway,
				},
			);
			toast.success('تم انشاء الطلب بنجاح');
			// PRINT RECEIPT
			// we need to fetch order details so that we can get the order in format accepted by print function
			const orderDetails = await getOrder(res.uuid);
			// reset products results (to update quantity)
			// refetchProducts();
			// RESET selected products
			resetSelections();

			// navigate(`/orders/${orderDetails.uuid}`);
			printJsonReceipt(orderDetails);
		} catch (error) {
			handleRequestError(error, (status, errorBody) => {
				if (status === 406) {
					const errorId = errorBody.id;
					if (errorId === 'CART_EMPTY') {
						return toast.error('يجب اختيار منتجات لإدخال الطلب');
					} else if (errorId === 'CART_NOT_VALID') {
						return toast.error('يوجد مشكلة في المنتجات المختارة');
					}
				}
			});
		} finally {
			setIsSubmitting(false);
		}
	};

	// to automatically focus on search when typing on keyboard
	useEffect(() => {
		const handlePress = (event) => {
			const element = document.getElementById('searchInput');
			element.focus();
		};
		document.addEventListener('keypress', handlePress);
		return () => {
			document.removeEventListener('keypress', handlePress);
		};
	}, []);

	const canSeeOrders = canAccess(findOrderPermission);

	return (
		<Page title="الكاشير">
			<Stack height={'100vh'}>
				<Stack direction={'row'} spacing={3} p={3} alignItems={'center'}>
					<Link to={canSeeOrders ? '/orders' : '/'} style={{textDecoration: 'none'}}>
						<Button startIcon={<ArrowForwardIosRoundedIcon />}>
							{canSeeOrders ? 'الطلبات' : 'الرئيسية'}
						</Button>
					</Link>
					<Typography variant="h3">إضافة طلب</Typography>
				</Stack>

				<Grid container flexGrow={1} sx={{borderTop: '1px solid rgba(145, 158, 171, 0.5)'}}>
					<Grid p={2} item xs={8}>
						<ProductsGridSelection
							flexBasis={1}
							handleItemQuantityChange={handleItemQuantityChange}
							checkAddedItemExists={checkAddedItemExists}
							handleAddItem={handleAddItem}
							handleRemoveItem={handleRemoveItem}
							addedProducts={addedProducts}
							resetSelections={resetSelections}
						/>
					</Grid>
					{/* Left section (Selected Products) */}
					<Grid
						item
						xs={4}
						sx={{
							borderLeft: '1px solid rgba(145, 158, 171, 0.5)',
						}}
					>
						<Stack height={'100%'}>
							<Stack spacing={2} p={3} pt={2} flexGrow={1}>
								<Typography variant="h5">تفاصيل الطلب</Typography>
								<Divider />
								<Typography fontWeight={'bold'}>المنتجات المضافة</Typography>
								<Box flexGrow={1} flexBasis={'10px'} overflow={'auto'}>
									<Stack spacing={1}>
										{/* <SelectedOrderItems
										 	product={product}
										 	handleItemQuantityChange={handleItemQuantityChange}
										 	handleRemoveItem={handleRemoveItem}
										 	key={product.id}
										 /> */}
										{addedProducts.map((cartItem) => (
											<CartItem
												item={cartItem}
												handleItemQuantityChange={handleItemQuantityChange}
												handleRemoveItem={handleRemoveItem}
												key={cartItem.id}
											/>
										))}
									</Stack>
								</Box>
							</Stack>
							<Stack
								height={'150px'}
								justifyContent={'space-between'}
								sx={{backgroundColor: '#076458', color: 'white'}}
								p={3}
							>
								<Stack alignItems="center" spacing={1} direction="row" justifyContent="space-between">
									<Typography variant="h5">
										المجموع:
										{getCartItemsTotalPrice(addedProducts)}
									</Typography>

									<TextField
										variant="outlined"
										size="small"
										value={downPayment}
										InputProps={{
											sx: {background: 'white'},

											startAdornment: <InputAdornment position="end">العربون</InputAdornment>,
										}}
										disabled={isNullOrEmpty(addedProducts)}
										sx={{width: 150}}
										onChange={(e) => {
											let v = getNumber(e.target.value, 0);
											if (v > getCartItemsTotalPrice(addedProducts)) return;
											setDownPayment(Math.abs(v));
										}}
									/>
								</Stack>

								<Stack spacing={1} direction="row" justifyContent="space-between">
									<Button
										onClick={() => handleBuyButton('cash')}
										variant="contained"
										color="secondary"
										fullWidth
										sx={{color: 'black'}}
										disabled={addedProducts.length == 0 || isSubmitting}
									>
										كاش
									</Button>
									<Button
										onClick={() => handleBuyButton('pos')}
										variant="outlined"
										color="secondary"
										fullWidth
										sx={{color: 'white'}}
										disabled={addedProducts.length == 0 || isSubmitting}
									>
										بطاقة
									</Button>
									{accountIs('demo') && (
										<Button
											onClick={() => handleBuyButton('t_lync')}
											variant="outlined"
											color="secondary"
											fullWidth
											sx={{color: 'white'}}
											disabled={addedProducts.length == 0 || isSubmitting}
										>
											اونلاين
										</Button>
									)}
								</Stack>
							</Stack>
						</Stack>
					</Grid>
				</Grid>
			</Stack>
		</Page>
	);
}
