import * as React from 'react';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function InfoTip({title, sx = {}}) {
	return (
		// enterTouchDelay is workaround to show tip in mobile
		<Tooltip sx={{mb: 1, ...sx}} enterTouchDelay={0} title={title}>
			<IconButton style={{fontSize: 16}} size="small">
				<InfoIcon fontSize="inherit" />
			</IconButton>
		</Tooltip>
	);
}
