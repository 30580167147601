export const shippingProviders = {
	darb: {
		id: 'darb',
		name: 'درب السبيل',
		bgcolor: 'red',
	},
	vanex: {
		id: 'vanex',
		name: 'فانكس',
		bgcolor: '#0155CC',
		iconWidth: 50,
		iconHeight: 15,
		iconPath: 'vanex-icon.png',
		darkIconPath: 'vanex-icon-dark.png',
	},
	foxline: {
		id: 'foxline',
		name: 'foxline',
		bgcolor: 'black',
		customSettingsPageLink: `/plugins/foxline`,
		hidden: true,
	},
	nawris: {id: 'nawris', name: 'النورس', bgcolor: 'orange'},
};
