// A custom hook that builds on useLocation to parse

const {useMemo} = require('react');
const {useLocation} = require('react-router-dom');

// the query string for you.
export default function useQueryString() {
	const {search} = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}
