import {DateTime} from 'luxon';

export const getDateTimeString = (dateStr) => {
	if (!dateStr) return '';

	const arr = dateStr.split('T');
	const date = arr[0];
	const time = arr[1].replace('.000Z', '');
	return `التاريخ ${date} الوقت ${time}`;
};

// // 2022-02-23T14:52:53.000Z

export const getDateTime = (dateStr) => {
	if (!dateStr) return '';
	dateStr = DateTime.fromISO(dateStr).toISO();
	const arr = dateStr.split('T');
	const date = arr[0];
	const time = arr[1].replace('.000Z', '');
	return [date, time];
};

export const getUtcDateTimeFromISO = (datetime) => {
	return new Date(datetime).toLocaleString('ar-AE');
	// return DateTime.fromISO(datetime).toLocaleString();
};

export const getFormattedDate = (date) => {
	let month = (date.getMonth() + 1).toString();
	month = month.length > 1 ? month : '0' + month;
	let day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;
	return {month, day, year: date.getFullYear()};
};

export const getNewExpirationDate = (currentExpirationDate, addNumberOfDays) => {
	if (!currentExpirationDate) currentExpirationDate = DateTime.now().toISO();
	// TODO if currentExpirationDate < now then use current date instead of currentExpirationDate
	return DateTime.fromISO(currentExpirationDate).plus({days: addNumberOfDays}).toISODate();
};
