import {Card, Container, Stack, TextField, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as yup from 'yup';
import FormikWrapper from '../../components/wrappers/FormikWrapper.js';
import Picker from 'src/components/inputs/Picker';
import SubmitButton from '../../components/buttons/SubmitButton.js';
import useFind from '../../hooks/useFind.js';
import PhoneInput from '../../PhoneInput.js';
import {getCities, getOrder, updateOrder} from '../../utils/AdminApi.js';
import {isNullOrEmpty} from '../../utils/helperMethods.js';
import React from 'react';
import {getCity} from '../../utils/cityUtils.js';
import {LoadingView} from 'src/routes.js';
import {getNumber} from 'src/utils/numberUtils.js';

const UpdateOrderDetails = () => {
	const [order, setOrder] = useState();
	const navigate = useNavigate();
	const {id} = useParams();
	const [cities] = useFind(getCities);

	// removes the city from the full address and return base address
	const getAddressWithoutCity = (fullAddress) => {
		return fullAddress
			.split(' ')
			.filter((word) => word !== order?.delivery_info?.city)
			.join(' ');
	};
	const getPureAddress = (fullAddress, delvInfo) => {
		let address =
			delvInfo?.type === 'delivery'
				? fullAddress !== undefined
					? getAddressWithoutCity(fullAddress)
					: ''
				: '';
		return address;
	};

	const orderAddress = getPureAddress(order?.address, order?.delivery_info);

	// fetching the order to be updated
	useEffect(() => {
		getOrder(id)
			.then(async (res) => {
				setOrder(res);
			})
			.catch((err) => alert(err));
	}, []);

	// navigate to order page
	const navigateToOrderPage = () => {
		navigate(`/orders/${id}`, {replace: true});
	};

	// determines whether info were changed or not by comparing formik values with fetched order values while checking for undefined fetched values

	const handleSubmit = async (values) => {
		const {name, discount, down_payment, note, phone, address, city, district} = values;

		const nweOrder = {
			name,
			phone,
			address: isNullOrEmpty(city) ? address : `${city} ${address}`,
			delivery_info: {
				...order?.delivery_info,
				city,
				address,
				// prevent sending emptyh string district
				district: isNullOrEmpty(district) ? undefined : district,
			},
			// discount: getNumber(discount),
			note,
			// down_payment: getNumber(down_payment),
		};
		try {
			await updateOrder(order.uuid, nweOrder);
			toast.success('تم تعدبل البيانات بنجاح');
			navigateToOrderPage();
		} catch (error) {
			toast.error('حدث خطأ غير متوقع');
		}
	};

	if (!order) return <LoadingView />;

	return (
		<Container>
			<FormikWrapper
				values={{
					name: order?.customer?.name ?? '',
					phone: order?.customer?.phone ?? '',
					address: orderAddress,
					city: order?.delivery_info?.city ?? '',
					district: order?.delivery_info?.district ?? '',
					note: order?.note ?? '',
					discount: order?.discount ?? '',
					down_payment: order?.down_payment ?? '',
					delivery_method: order?.delivery_info?.type ?? 'delivery',
				}}
				schema={schema}
				handleSubmit={handleSubmit}
			>
				{({values, isSubmitting, getInputProps, setFieldValue}) => {
					const selectedCity = getCity(cities, values.city);
					const cityHasDistricts = !isNullOrEmpty(selectedCity?.districts);
					return (
						<Card sx={{p: 2}}>
							<Stack spacing={3}>
								<Typography variant="h3">{' تعديل الطلب ' + '#' + order?.id}</Typography>

								<TextField fullWidth type="text" label="اسم الزبون" {...getInputProps('name')} />

								<PhoneInput
									label="رقم الهاتف"
									placeholder="رقم الهاتف"
									{...getInputProps('phone')}
									fullWidth
									type="tel"
								/>

								{order?.delivery_info?.type === 'delivery' && (
									<>
										<Picker
											options={cities}
											label="المدينة"
											labelKey="name"
											valueKey="name"
											defaultValue="طرابلس"
											{...getInputProps('city')}
											onChange={(e) => {
												setFieldValue('city', e.target.value);
												setFieldValue('district', '');
											}}
										/>
										{cityHasDistricts && (
											<Picker
												options={selectedCity?.districts ?? []}
												label="المنطقة"
												labelKey="name"
												valueKey="name"
												{...getInputProps('district')}
											/>
										)}
										<TextField
											label="العنوان"
											placeholder="العنوان"
											{...getInputProps('address')}
											fullWidth
											type="text"
										/>
									</>
								)}

								<TextField
									label="ملاحظات"
									placeholder="ملاحظات اضافية عن الطلب"
									{...getInputProps('note')}
									fullWidth
									minRows={3}
									multiline
									type="text"
								/>

								{/* <TextField
									fullWidth
									type="text"
									inputMode="numeric"
									pattern="\d*"
									InputProps={{min: 0}}
									label="تخفيض"
									{...getInputProps('discount')}
								/> */}

								{/* <TextField
									fullWidth
									type="text"
									inputMode="numeric"
									pattern="\d*"
									InputProps={{min: 0}}
									label="العربون"
									{...getInputProps('down_payment')}
								/> */}

								<SubmitButton isSubmitting={isSubmitting}>تعدبل</SubmitButton>
							</Stack>
						</Card>
					);
				}}
			</FormikWrapper>
		</Container>
	);
};
export default UpdateOrderDetails;

const schema = yup.object({
	name: yup.string(),
	phone: yup.string('').min(9, 'رقم الهاتف يجب ان يتكون من ٩ ارقام'),
	address: yup.string(),
	city: yup.string().when('delivery_method', (delivery_method, field) => {
		if (delivery_method === 'delivery') {
			return field.required('يرجي اختيار المدينة');
		} else {
			// no need for validation
			return field;
		}
	}),
	district: yup.string().when('city', (city, field) => {
		const cityHasDistricts = city === 'طرابلس' || city === 'بنغازي';
		if (cityHasDistricts) {
			// district is required when city has districts
			return field.required('يرجي اختيار المنطقة');
		} else {
			// no need for validation
			return field;
		}
	}),
});
