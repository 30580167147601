import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import {Icon} from '@iconify/react';
import {AppBar, Box, IconButton, Toolbar} from '@mui/material';
// material
import {experimentalStyled as styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {APPBAR_DESKTOP, APPBAR_MOBILE, DRAWER_WIDTH} from 'src/constants';
import {accountIs} from 'src/utils/siteUtils';
// components
import {MHidden} from '../../components/@material-extend';
import NotificationWidget from '../../components/_dashboard/notification/NotificationWidget.js';
// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({theme}) => ({
	boxShadow: 'none',
	backdropFilter: 'blur(6px)',
	WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
	[theme.breakpoints.up('lg')]: {
		width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
	},
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
	left: 0,
	minHeight: APPBAR_MOBILE,
	[theme.breakpoints.up('lg')]: {
		minHeight: APPBAR_DESKTOP,
		padding: theme.spacing(0, 5),
	},
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
	onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({onOpenSidebar, pthNameObj}) {
	const navigate = useNavigate();
	return (
		<RootStyle>
			<ToolbarStyle>
				<MHidden width="lgUp">
					<IconButton onClick={onOpenSidebar} sx={{mr: 1, color: 'white'}}>
						<Icon icon={menu2Fill} />
					</IconButton>
				</MHidden>
				<Box sx={{flexGrow: 1}} />
				{/* <Stack direction="row" spacing={{xs: 0.5, sm: 1.5}}></Stack> */}
				<NotificationWidget pathNameObj={pthNameObj} />
				{/* <IconButton onClick={() => navigate('/barcode')} sx={{mr: 1, color: 'white'}}>
					<img src="/static/icons/barcode.png" height={25} />
				</IconButton> */}
			</ToolbarStyle>
		</RootStyle>
	);
}
