import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import {getSiteCurrency} from 'src/utils/selectors';
/* eslint-disable no-unreachable */
import DeleteIcon from '@mui/icons-material/Delete';
import {Box, Button, Divider, IconButton, Stack, TextField, Typography} from '@mui/material';
import {getImageSrc} from 'src/utils/productUtils';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import {toast} from 'react-toastify';

export default function CartItem({item, handleItemQuantityChange, handleRemoveItem}) {
	return (
		<Stack spacing={2}>
			<Stack direction="row" justifyContent="space-between">
				<Stack alignItems="center" spacing={2} direction="row">
					<img loading="lazy" alt="product" src={getImageSrc(item)} width={45} height={45} />
					<Stack>
						<Typography variant="subtitle2">
							{item.name} {item.variation?.name && `- ${item.variation?.name}`}
						</Typography>
						<Stack spacing={1} direction="row" justifyContent="space-between">
							<Typography sx={{fontSize: 14}}>
								الكمية المتوفرة: {item.infinite ? '∞' : item.quantity}
							</Typography>
							<Typography sx={{fontSize: 14}}>
								السعر: {calcPriceAfterDiscount(item.price, item.discount)} {getSiteCurrency()}
							</Typography>
						</Stack>
					</Stack>
				</Stack>
				{/* 		<Stack alignItems="center" spacing={1} direction="row" justifyContent="space-between">
					<TextField
						type="number"
						label="الكمية"
						placeholder="الكمية"
						size="small"
						name="selectedQuantity"
						defaultValue={1}
						sx={{maxWidth: 65, fontSize: 5}}
						value={item.selectedQuantity}
						onChange={(e) => handleItemQuantityChange(item.id, e.target.value)}
					/>

					<IconButton
						sx={{opacity: 0.8}}
						color="error"
						onClick={() => {
							handleRemoveItem(item.id);
						}}
					>
						<DeleteIcon fontSize="small" />
					</IconButton>
				</Stack>
 */}
				<Stack spacing={1} direction="row" alignItems={'center'}>
					<Stack sx={{p: 0, minWidth: '0px', borderRadius: '4px 0 0 4px'}} direction={'row'}>
						<Button
							variant="contained"
							size="small"
							sx={{px: 1, minWidth: '0px', borderRadius: '4px 0 0 4px'}}
							onClick={() => {
								if (item.infinite || item.selectedQuantity < item.quantity)
									handleItemQuantityChange(item.id, item.selectedQuantity + 1);
								else toast.warning('وصلت لحد الكمية المتاحة');
							}}
						>
							<AddRoundedIcon fontSize="16px" />
						</Button>
						<Box
							display={'flex'}
							alignItems={'center'}
							px={0.5}
							py={0.5}
							borderTop={'1px solid #076458'}
							borderBottom={'1px solid #076458'}
						>
							<Typography
								variant="body2"
								fontWeight={'bold'}
								sx={{cursor: 'pointer'}}
								fontSize={'14px'}
								px={1}
							>
								{item.selectedQuantity}
							</Typography>
						</Box>
						<Button
							variant="contained"
							size="small"
							sx={{px: 1, minWidth: '0px', borderRadius: '0 4px 4px 0'}}
							onClick={() => {
								if (item.selectedQuantity > 1) handleItemQuantityChange(item.id, item.selectedQuantity - 1);
								else {
									handleRemoveItem(item.id);
								}
							}}
						>
							<RemoveRoundedIcon fontSize="16px" />
						</Button>
					</Stack>
					<IconButton
						sx={{opacity: 0.8}}
						color="error"
						onClick={() => {
							handleRemoveItem(item.id);
						}}
					>
						<DeleteIcon fontSize="small" />
					</IconButton>
				</Stack>
			</Stack>
			<Divider />
		</Stack>
	);
}
