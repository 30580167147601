import {Select, Stack, Typography} from '@mui/material';
import React from 'react';
import {orderStatusOptions} from 'src/utils/orderUtils';
import Picker from 'src/components/inputs/Picker';

export default function OrderStatusPicker({
	defaultStatus,
	size = 'small',
	options = orderStatusOptions,
	status,
	onStatusChange,
	sx = {},
}) {
	return (
		<Picker
			defaultValue={defaultStatus}
			sx={sx}
			size={size}
			value={status}
			onChange={onStatusChange}
			options={options}
			labelKey="label"
			valueKey="key"
			label="حالة الطلب"
		/>
	);
}
