import {
	Button,
	Card,
	Checkbox,
	Container,
	FormControlLabel,
	Stack,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import {cloneDeep, isEqual} from 'lodash';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import FabSaveButton from 'src/components/buttons/FabSaveButton';
import InfoTip from 'src/components/ui/InfoTip';
import useBulkActions from 'src/hooks/useBulkActions';
import {getCities, getDeliveryMethodById, updateDeliveryMethods} from 'src/utils/AdminApi';
import {isNullOrEmpty, updateWhere} from 'src/utils/helperMethods';
import {getNumber} from 'src/utils/numberUtils';

export default function UpdateDeliveryMethodDistricts() {
	const [districtsSettingsState, setDistrictsSettingsState] = useState([]);

	// get data from api
	const {id, city: cityName} = useParams();

	const {data: cities = []} = useQuery('cities', getCities, {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
	const {data: deliveryMethodInfo, refetch} = useQuery(
		['delivery_method', id],
		() => getDeliveryMethodById(id),
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) =>
				setDistrictsSettingsState(
					data?.details?.cities?.find((c) => c.name === cityName)?.districts ?? [],
				),
		},
	);

	const {
		selections,
		setSelections,
		bulkInputs,
		setBulkInputs,
		reset,
		getHandleCheck,
		getMainCheckboxProps,
	} = useBulkActions({
		data: districtsSettingsState,
		key: 'name',
		initialFormValues: {
			enabled: true,
		},
	});

	const onSubmitDistrictsForm = async (e) => {
		e.preventDefault();

		const updates = deliveryMethodInfo.details.cities.map((city) => {
			if (city.name === cityName) {
				return {...city, districts: [...districtsSettingsState]};
			}
			return city;
		});

		await updateDeliveryMethods(id, deliveryMethodInfo.name, {
			cities: updates,
		});

		refetch();
	};

	const cityObject = cities.find((c) => c.name === cityName);

	if (!deliveryMethodInfo || !cityObject) return;

	const oldDistrictsSettings =
		deliveryMethodInfo.details?.cities?.find((c) => c.name === cityName)?.districts ?? [];

	//  length check is added to avoid show save change button before loading the state correctly
	const dataChanged =
		districtsSettingsState.length > 0 && !isEqual(oldDistrictsSettings, districtsSettingsState);

	return (
		<Container>
			{/* TODO link back to delivery method update */}
			<Stack px={1} spacing={1} direction="row" alignItems="center">
				<Checkbox size="small" {...getMainCheckboxProps()} />
				<Typography variant="h4">تعديل اسعار المناطق</Typography>
			</Stack>

			<form onSubmit={onSubmitDistrictsForm}>
				<Stack spacing={2}>
					{cityObject.districts.map((district) => {
						const citySettings = deliveryMethodInfo.details.cities.find((c) => c.name === cityName) ?? {};

						const districtSettings =
							districtsSettingsState.find((dist) => dist.name === district.name) ?? {};

						return (
							<Card sx={{p: 1, mt: 1}}>
								<Stack alignItems="center" direction="row" justifyContent="space-between">
									<Stack spacing={1} direction="row" alignItems="center">
										<Checkbox
											size="small"
											onChange={getHandleCheck(district)}
											checked={Boolean(selections[district.name])}
										/>
										<Typography>{district.name}</Typography>
										<Switch
											size="small"
											onChange={(e) =>
												setDistrictsSettingsState(
													updateWhere(
														districtsSettingsState,
														{enabled: e.target.checked},
														(d) => d.name === district.name,
													),
												)
											}
											checked={districtSettings.enabled !== false}
										/>
									</Stack>
									<Stack direction="row">
										<TextField
											label="السعر"
											placeholder="السعر"
											sx={{width: 75}}
											type="number"
											value={districtSettings.price ?? ''}
											onChange={(e) => {
												setDistrictsSettingsState(
													updateWhere(
														districtsSettingsState,
														{price: e.target.value},
														(d) => d.name === district.name,
													),
												);
											}}
										/>
										<InfoTip
											title={
												`في حالة ترك حقل السعر فارغ سيتم استخدام السعر الخاص بالمدينة` +
												` = ${citySettings.price} د.ل`
											}
										/>
									</Stack>
								</Stack>
							</Card>
						);
					})}

					{!isNullOrEmpty(selections) && (
						<Stack
							sx={{
								zIndex: 10,
								position: 'fixed',
								bottom: 50,
								width: {xs: '90vw', md: 'inherit'},
								left: '50%',
								transform: 'translate(-50%, -50%)',
								background: 'white',
								p: 2,
								px: 4,
								borderRadius: 3,
								boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
							}}
							spacing={1}
							direction="row"
							flex={1}
						>
							<Stack flex={1} spacing={2} direction="row" alignItems="center">
								<FormControlLabel
									label="تفعيل"
									control={
										<Switch
											size="small"
											onChange={(e) => setBulkInputs({...bulkInputs, enabled: e.target.checked})}
											checked={bulkInputs.enabled}
										/>
									}
								/>

								<TextField
									type="number"
									label="السعر"
									name="price"
									value={bulkInputs.price}
									onChange={(e) => setBulkInputs({...bulkInputs, price: e.target.value})}
									InputLabelProps={{shrink: true}}
									helperText="في حالة ترك هذا الحقل فارغاً سيتم استخدام سعر المدينة الاساسي"
								/>

								<Button
									variant="outlined"
									onClick={async () => {
										// [citiesSettingsState] vs selections
										let _districtsSettingsState = cloneDeep(districtsSettingsState);
										_districtsSettingsState.forEach((c) => {
											if (c.name in selections) {
												c.enabled = bulkInputs.enabled;
												c.price = bulkInputs.price;
											}
										});
										setDistrictsSettingsState(_districtsSettingsState);
										reset();
									}}
								>
									حفظ
								</Button>
							</Stack>
						</Stack>
					)}
				</Stack>

				{dataChanged && <FabSaveButton type="submit" />}
			</form>
		</Container>
	);
}
