import {useInfiniteQuery, useQuery} from 'react-query';
import useUrlFilters from 'src/hooks/useUrlFilters';
import {getOrders} from 'src/utils/AdminApi';
/**
 *
 * @returns {import('react-query').UseQueryResult<TData, TError>}
 */
const limit = 9;
const useOrdersQuery = () => {
	const {filtersQueryString} = useUrlFilters({ignoreNullOrEmpty: true});

	const {refetch, data, fetchNextPage, hasNextPage} = useInfiniteQuery(
		['order', filtersQueryString],
		({pageParam = 0}) => {
			// console.log('qsfilters', qsFilters);
			return getOrders(
				`?_limit=${limit}&_start=${pageParam * limit}&_sort=id:desc&${filtersQueryString}`,
			);
		},
		{
			// will not try to check for updates until number of ms passed
			// staleTime: 1000 * 5,
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage, allPages) =>
				lastPage.length < limit ? undefined : allPages.length,
		},
	);
	return {
		orders: Array.isArray(data?.pages) ? data.pages.flat() : [],
		fetchNextPage,
		hasNextPage,
		refetch,
	};
	// return useQuery(
	// 	['order', filtersQueryString],
	// 	() => {
	// 		// console.log('qsfilters', qsFilters);
	// 		return getOrders(`?_limit=-1&_sort=id:desc&${filtersQueryString}`);
	// 	},
	// 	{
	// 		// will not try to check for updates until number of ms passed
	// 		staleTime: 1000 * 10,
	// 		// refetchOnMount: false,
	// 		refetchOnWindowFocus: false,
	// 	},
	// );
};

export default useOrdersQuery;
