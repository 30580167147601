import {Button} from '@mui/material';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import DynamicForm from 'src/components/DynamicForm/DynamicForm';
import {hideLoaderAction, showLoaderAction} from 'src/reducers/app/appActions';
import {updateOrder} from 'src/utils/AdminApi';
import {createFoxlineOrder} from './api';

const provider = 'foxline';
export default function FoxlineSendOrderButton({order}) {
	const address = order.address ?? '';
	const {countryId, city, street = '', postal = ''} = order.delivery_info;
	const dispatch = useDispatch();

	const sendOrder = async () => {
		dispatch(showLoaderAction);
		try {
			// make request to send order to foxline
			const result = await createFoxlineOrder({
				description: '', //Goods to ship
				quantity: getTotalItemsCount(order.items),
				isExpress: true,
				receiver: {
					firstName: order.customer.name,
					lastName: '',
					mobile: order?.customer?.phone, // New Receiver will be created if mobile doesn't exist.
					mobile2: order?.customer?.phone,
					street,
					postal,
					street2: address,
					area: address,
					city: city,
					country_Id: countryId,
				},
				isInsured: false,
				amountInsured: 0, // amount in Euro,  keep it 0 if isInsured is false (no insurance on goods)
				notes: '',
			});

			toast.success('تم انشاء الشحنة بنجاح');
			// make request to update order info with the shipping id generated by foxline api
			// so that we can use it to get shipping details
			const changes = {
				...order.delivery_info,
				shippingId: result.data.shippingId,
				shippingProvider: provider,
			};
			updateOrder(order.uuid, {
				delivery_info: changes,
			});
		} catch (err) {
			console.log(err);
			toast.error('حدث خطأ غير متوقع');
		} finally {
			dispatch(hideLoaderAction);
		}
	};
	return (
		<>
			<Button
				// endIcon={<img src="/third-party-icons/vanex-icon.png" height={15} />}
				variant="contained"
				sx={{bgcolor: '#000000'}}
				onClick={sendOrder}
			>
				شحن بواسطة foxline
			</Button>
		</>
	);
}

const getTotalItemsCount = (items = []) => {
	let sum = 0;
	items.forEach((item) => {
		sum += item.quantity;
	});
	return sum;
};
