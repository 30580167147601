import React from 'react';
import {confirmable, createConfirmation} from 'react-confirm';
import BasicModal from './BasicModal';
import {Button, Stack} from '@mui/material';
import ThemeConfig from 'src/theme';

const Confirmation = (props) => {
	console.log('props', props);
	const {
		okLabel = 'OK',
		cancelLabel = 'Cancel',
		title = 'Confirmation',
		confirmation,
		show,
		proceed,
		dismiss,
		cancel,
	} = props;
	return (
		<ThemeConfig>
			<BasicModal title={title} onClose={() => dismiss()} isVisible={show}>
				<Stack spacing={3} justifyContent="space-between">
					<Button variant="contained" onClick={() => proceed(true)}>
						نعم
					</Button>

					<Button variant="outlined" onClick={() => proceed(false)}>
						لا
					</Button>
				</Stack>
			</BasicModal>
		</ThemeConfig>
	);
};

export function askYesOrNo(title, proceedLabel = 'OK', cancelLabel = 'cancel', options = {}) {
	return createConfirmation(confirmable(Confirmation))({
		title,
	});
}
