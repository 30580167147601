import {getSite} from './selectors';

export const accountIs = (hostname, includeDemo = false) => {
	const host = getSite()?.host?.toLowerCase();
	return host?.includes(hostname) || (includeDemo && host?.includes('demo.vetrinas.ly'));
};

export const accountOneOf = (...testSites) => {
	// always add demo website to ab testing
	return testSites.some((site) => accountIs(site, true));
};
