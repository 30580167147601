import {Button, Card, Container, Fab, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import Counter from 'src/components/inputs/Counter';
import Picker from 'src/components/inputs/Picker';
import {LoadingView} from 'src/routes';
import {
	addOrderItem,
	deleteOrderItems,
	getOrder,
	getProducts,
	updateOrderItem,
} from 'src/utils/AdminApi';
import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import filterDeleted from 'src/utils/filterDeleted';
import {handleRequestError, isNullOrEmpty, updateArray} from 'src/utils/helperMethods';
import {getSiteCurrency} from 'src/utils/selectors';
import {getImageSrc} from 'src/utils/productUtils';
import useMultipleChanges from 'src/hooks/useMultipleChanges';
import MuiLink from 'src/components/ui/StyledLink';
import CreatableSelect from 'src/components/inputs/CreatableSelect';
import {useDebounce} from 'src/hooks/useDebounce';
export default function UpdateOrderItemsPage() {
	let {id} = useParams();
	const [orderItems, setItems] = useState([]);
	const {data: order, isLoading} = useQuery(['order', id], () => getOrder(id), {
		enabled: !!id,
		refetchOnWindowFocus: false,
		// we need local state to update items after changes are saved
		// we need to filter soft deleted items
		onSuccess: (data) => setItems(filterDeleted(data.items)),
	});

	const {changes, clearItemChanges, handleValueChange} = useMultipleChanges();
	const onSaveItemChangesClicked = async (item, changes) => {
		try {
			const updates = {quantity: changes[item.id].quantity};
			const res = await updateOrderItem(item.id, updates);
			// to hide save button for this item
			clearItemChanges(item.id);
			// update item state so that our compare changes function work fine if user want to change the item again
			setItems(updateArray(orderItems, res, 'id'));
		} catch (error) {
			toast.error('حدث خطأ غير متوقع');
		}
	};

	const deleteItem = async (item) => {
		try {
			await deleteOrderItems(item.id, 'no reason');
			setItems(orderItems.filter((i) => i.id != item.id));
		} catch (err) {
			//
		}
	};

	if (isLoading) return <LoadingView />;

	return (
		<Container>
			<Card sx={{p: 2}}>
				<Stack spacing={2}>
					<Typography variant="h3">تعديل الطلب</Typography>

					<Stack spacing={1}>
						{orderItems.map((item) => {
							const itemChanges = changes[item.id];
							const itemChanged = !!itemChanges;
							const newQuantity = itemChanges?.quantity;
							return (
								<Card key={item.id} sx={{p: 1}}>
									<Stack
										alignItems={{xs: 'default', sm: 'center'}}
										direction={{xs: 'column', sm: 'row'}}
										justifyContent="space-between"
									>
										{/* metadata */}
										<Stack spacing={1}>
											<Stack spacing={2} direction="row">
												<img
													loading="lazy"
													alt={item.product.name}
													width={50}
													height={50}
													src={getImageSrc(item.product)}
												/>
												<Stack>
													<MuiLink to={`/products/${item.product.id}`}>
														<Typography>
															{item.product.name} {item.variation?.name && `- ${item.variation?.name}`}
														</Typography>
													</MuiLink>

													<Typography>
														{calcPriceAfterDiscount(item.price, item.discount)} {getSiteCurrency()}
													</Typography>
												</Stack>
											</Stack>
										</Stack>
										{/* actions */}
										<Stack justifyContent="space-between" alignItems="center" spacing={1} direction="row">
											<Counter
												defaultValue={item.quantity}
												onChange={(newValue) => handleValueChange(newValue, item, 'quantity')}
											/>
											{/* <Button onClick={() => deleteItem(item)}>delete</Button> */}

											<Fab
												sx={{opacity: itemChanged ? 1 : 0.5}}
												disabled={!itemChanged}
												onClick={() => onSaveItemChangesClicked(item, changes)}
												// sx={{position: 'fixed', right: 40, bottom: 80}}
												variant="extended"
												color="primary"
												size="small"
											>
												حفظ
											</Fab>
										</Stack>
										{/* <Typography>{item.variation ? item.variation.quantity : item.product.quantity}</Typography> */}
									</Stack>
								</Card>
							);
						})}
					</Stack>
					<Typography variant="h6">اضافة منتج اخر</Typography>
					<AddOrderItemForm
						orderId={order.id}
						alreadySelectedItems={orderItems.map((i) => ({
							productId: i.product.id,
							variationId: i.variation?.id,
						}))}
					/>
				</Stack>
			</Card>
		</Container>
	);
}

export function AddOrderItemForm({orderId, alreadySelectedItems = []}) {
	const [product, setProduct] = useState(null);
	const [filter, setFilter] = useState('');
	const debouncedFilter = useDebounce(filter, 250);
	const [variation, setVariation] = useState(0);
	const [selectedQuantity, setSelectedQuantity] = useState(1);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const {data: products = [], refetch} = useQuery(
		['products', debouncedFilter],
		() => {
			return getProducts(
				`?_limit=20&publish_at_null=true&publish_at_null=false&name_contains=${debouncedFilter}`,
			);
		},
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			staleTime: 1000 * 60 * 5,
		},
	);

	const productsToDisplay = products.filter((p) => {
		const existingItem = alreadySelectedItems.find(({productId}) => productId == p.id);
		if (existingItem != null) {
			// if there's no more than 1 variations or no variation is selected (unexpected) => ignore this option
			if (existingItem.variationId == null || p.variations.length === 1) return false;

			// check if every product variations already exist in old order items
			// all included => ignore it, else add it
			return !p.variations.every((v) =>
				alreadySelectedItems.find(({variationId}) => variationId == v.id),
			);
		}
		// product not exit
		return true;
	});

	const variationsToDisplay = product?.variations?.filter((variation) => {
		const existingItem = alreadySelectedItems.find(({variationId}) => variationId == variation.id);
		return !existingItem;
	});
	const onSubmit = async (e) => {
		e.preventDefault();
		if (isSubmitting) return;
		if (!product || (!isNullOrEmpty(product.variations) && !variation))
			return toast.error('يرجي اختيار المنتج');
		setIsSubmitting(true);
		const {price, discount} = variation ? variation : product;

		try {
			await addOrderItem({
				order: orderId,
				quantity: selectedQuantity,
				price: calcPriceAfterDiscount(price, discount),
				discount,
				product: product.id,
				variation: variation?.id,
			});
			window.location.reload();
		} catch (error) {
			console.log(error);
			handleRequestError(error, (status, errorBody) => {
				if (status === 406) {
					return toast.error('لا يوجد كمية متاجة');
				}
			});
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<Stack alignItems="center" spacing={2} direction="row">
				<CreatableSelect
					allowCreate={false}
					handleSearch={(term) => setFilter(term)}
					renderMenuItem={(props, option) => {
						return (
							<li {...props}>
								<Stack alignItems="center" spacing={1} direction="row">
									{option.id != -1 && (
										<img loading="lazy" width={25} height={25} src={getImageSrc(option)} />
									)}
									<p>{option.name}</p>
								</Stack>
							</li>
						);
					}}
					//
					//
					size="medium"
					fullWidth
					options={[...productsToDisplay]}
					name="product"
					label="المنتج"
					labelKey="name"
					selectedValue={product}
					valueKey="id"
					setSelectedValue={(newValue) => {
						setProduct(newValue);
						setVariation(0);
					}}
				/>

				{/* variaiton selection */}
				{/* don't show this selection if there's no variations for the selected product */}
				{!isNullOrEmpty(product) && !isNullOrEmpty(product.variations) && (
					<Picker
						sx={{minWidth: 100}}
						options={[{id: 0, name: 'اختر'}, ...variationsToDisplay]}
						name="variation"
						label="الاختيارات"
						labelKey="name"
						valueKey="id"
						value={variation?.id ?? 0}
						onChange={(e) => {
							// onChange(index, {
							// 	variation: product.variations.find((v) => v.id == e.target.value),
							// });
							setVariation(product.variations.find((v) => v.id == e.target.value) ?? 0);
						}}
					/>
				)}
			</Stack>
			<Stack alignItems="center" mt={1} spacing={1} direction="row" justifyContent="space-between">
				<Counter defaultValue={1} onChange={(newValue) => setSelectedQuantity(newValue)} />
				<Button disabled={isSubmitting} size="medium" type="submit" variant="contained">
					اضافة
				</Button>
			</Stack>
		</form>
	);
}
