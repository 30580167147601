import FilterIcon from '@mui/icons-material/Tune';
import {
	Checkbox,
	FormControlLabel,
	IconButton,
	Stack,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import {useState} from 'react';
import OrderStatusPicker from 'src/pages/Orders/show/OrderStatusPicker';
import OrderAssignPicker, {useSiteAdminsQuery} from 'src/pages/Orders/show/OrderAssignPicker';
import useDataSearch from 'src/hooks/useDataSearch';
import useUrlFilters from 'src/hooks/useUrlFilters';
import {orderStatusOptions} from 'src/utils/orderUtils';
import Picker from 'src/components/inputs/Picker';
import {accountIs} from 'src/utils/siteUtils';
export default function OrdersFilter() {
	const [isOpen, setisOpen] = useState(false);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const {filters, setQueryStringValue} = useUrlFilters({ignoreNullOrEmpty: true});
	const {data: allAdmins = []} = useSiteAdminsQuery();
	const {onSearch} = useDataSearch((value) => {
		setQueryStringValue('q', value);
	}, filters.q);

	const onStatusChange = (e) => {
		setQueryStringValue('status', e.target.value ?? '');
	};
	const onAssigneeChange = (assigneeIds = []) => {
		setQueryStringValue('assignee', assigneeIds?.[0] ?? '');
	};

	return (
		<>
			<Stack rowGap={2} flexWrap="wrap" my={1} spacing={2} direction={{sm: 'row', xs: 'column'}}>
				<Stack flex={1} spacing={1} direction="row" justifyContent="space-between">
					<TextField
						label="بحث"
						sx={{minWidth: '200px'}}
						size="medium"
						fullWidth
						placeholder="البحث بإسم الزبون"
						type="text"
						// helperText="يمكن البحث حسب اسم رقم الزبون، رقم الطلب، اسم او باركود المنتج"
						onChange={onSearch}
					/>
					{isSmallScreen && (
						<IconButton onClick={() => setisOpen(!isOpen)}>
							<FilterIcon />
						</IconButton>
					)}
				</Stack>
				{(!isSmallScreen || isOpen) && (
					<>
						<OrderStatusPicker
							options={[
								{
									key: '',
									label: 'الكل',
								},
								...orderStatusOptions,
							]}
							size="medium"
							onStatusChange={onStatusChange}
							status={filters.status ?? ''}
							sx={{minWidth: 200}}
						/>
						{allAdmins.length > 0 && (
							<OrderAssignPicker
								sx={{minWidth: 200}}
								label="المسوؤل عن الطلب"
								size="medium"
								noSelectionLabel="الكل"
								onChange={onAssigneeChange}
								admins={allAdmins}
								value={filters.assignee ?? ''}
							/>
						)}

						{/* <Picker
							sx={{minWidth: 150}}
							value={filters.guestId_null ?? 'none'}
							onChange={(e) => {
								setQueryStringValue('guestId_null', e.target.value);
							}}
							options={[
								{
									label: 'الكل',
									value: 'none',
								},
								{
									label: 'الموقع',
									value: 'false',
								},
								{
									label: 'يدوي',
									value: 'true',
								},
							]}
							labelKey="label"
							valueKey="value"
							label="مصدر الطلب"
						/> */}
						{allAdmins.length > 0 && (
							<Picker
								sx={{minWidth: 150}}
								value={filters.creator ?? 'none'}
								onChange={(e) => {
									setQueryStringValue('creator', e.target.value);
								}}
								options={[
									{
										label: 'الكل',
										value: 'none',
									},
									...allAdmins.map((admin) => ({
										label: admin.name,
										value: admin.id,
									})),
								]}
								labelKey="label"
								valueKey="value"
								label="مدخل الطلب"
							/>
						)}
						<Picker
							sx={{minWidth: 150}}
							value={filters['payment.gateway'] ?? 'none'}
							onChange={(e) => {
								setQueryStringValue('payment.gateway', e.target.value);
							}}
							options={[
								{
									label: 'الكل',
									value: 'none',
								},
								{
									label: 'كاش',
									value: 'cash',
								},
								{
									label: 'بطاقة مصرفية',
									value: 'pos',
								},
								{
									label: 'الكتروني',
									value: 't_lync',
								},
							]}
							labelKey="label"
							valueKey="value"
							label="طريقة الدفع"
						/>
						<Picker
							sx={{minWidth: 150}}
							value={filters.payment_received ?? 'none'}
							onChange={(e) => {
								console.log('value', e.target.value);
								setQueryStringValue('payment_received', e.target.value);
							}}
							options={[
								{
									label: 'الكل',
									value: 'none',
								},
								{
									label: 'تم التسوية',
									value: 1,
								},
								{
									label: 'لم يتم التسوية',
									value: 0,
								},
							]}
							labelKey="label"
							valueKey="value"
							label="التسوية"
						/>
						<Picker
							sx={{minWidth: 150}}
							value={filters.fulfillment_type ?? 'none'}
							onChange={(e) => {
								setQueryStringValue('fulfillment_type', e.target.value);
							}}
							options={[
								{
									label: 'الكل',
									value: 'none',
								},
								{
									label: 'توصيل',
									value: 'delivery',
								},
								{
									label: 'استلام شخصي',
									value: 'pickup',
								},
							]}
							labelKey="label"
							valueKey="value"
							label="طريقة الاستلام"
						/>

						<Stack spacing={1} direction="row">
							<TextField
								InputLabelProps={{shrink: true}}
								pattern="\d{4}-\d{2}-\d{2}"
								fullWidth
								label="من"
								placeholder="من"
								onChange={(e) => setQueryStringValue('fulfillment_date_gte', e.target.value ?? '')}
								value={filters.fulfillment_date_gte}
								type="date"
								required
							/>
							<TextField
								InputLabelProps={{shrink: true}}
								pattern="\d{4}-\d{2}-\d{2}"
								fullWidth
								label="الي"
								placeholder="الي"
								// '2023-02-09T23:59:59.000Z'
								onChange={(e) => setQueryStringValue('fulfillment_date_lte', e.target.value ?? '')}
								value={filters.fulfillment_date_lte}
								type="date"
								required
							/>
						</Stack>

						{/* <FormControlLabel
							control={
								<Checkbox
									onChange={(e) => setQueryStringValue('down_payment_gte', e.target.checked ? 1 : 'none')}
									checked={filters.down_payment_gte == 1}
								/>
							}
							label="مع عربون فقط"
						/> */}
					</>
				)}
			</Stack>
			{/* {!isNullOrEmpty(filters) && `${Object.keys(filters).length} filters applied`} */}
		</>
	);
}
