import {stringify} from 'qs';
import {useLocation, useNavigate} from 'react-router-dom';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {filter} from 'src/utils/objectUtils';
const defaultOptions = {ignoreNullOrEmpty: false};
const useUrlFilters = (options = defaultOptions) => {
	const location = useLocation();
	const locationSearch = location.search;
	const params = new URLSearchParams(locationSearch);
	let filters = {};
	params.forEach((value, key) => {
		if (options.ignoreNullOrEmpty && (isNullOrEmpty(value) || value === 'none')) return;
		filters[key] = value;
	});
	const navigate = useNavigate();

	const setQueryStringValue = (key, newValue) => {
		if (newValue === undefined) return;

		if (options.ignoreNullOrEmpty && isNullOrEmpty(newValue)) {
			delete filters[key];
			navigate({
				search: stringify(filters),
			});
		} else {
			navigate({
				search: stringify({...filters, [key]: newValue}),
			});
		}
	};

	const removeFilter = (key) => {
		delete filters[key];
		navigate({
			search: stringify(filters),
		});
	};
	return {
		filters,
		filtersQueryString: stringify(filters),
		setQueryStringValue,
		removeFilter,
	};
};

export default useUrlFilters;
