export const defaultImageSrc = '/static/default-product-image.png';

/**
 *
 * @param {*} imageObj image object
 * @param {*} requestedFormat optional format to use 'small' 'thumbnail' 'large'
 * @param {*} fallbackFormat in case requested format not exist use fallback format
 * @returns {string} image url with the requested format or fallback or base url or default image url
 */
export const getImageFormat = (imageObj, requestedFormat = 'small', fallbackFormat = null) => {
	if (requestedFormat === 'small ' && !fallbackFormat) {
		fallbackFormat = 'thumbnail';
	}
	if (requestedFormat === 'thumbnail ' && !fallbackFormat) {
		fallbackFormat = 'small';
	}

	let imgWithRequestedFormat = imageObj?.formats?.[requestedFormat]?.url;
	let imgWithFallbackFormat = imageObj?.formats?.[fallbackFormat]?.url;

	return imgWithRequestedFormat ?? imgWithFallbackFormat ?? imageObj?.url ?? defaultImageSrc;
};
