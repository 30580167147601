import {Button, Card, Divider, Stack, Typography} from '@mui/material';
import React from 'react';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {getNumber, getPriceNumber} from 'src/utils/numberUtils';
import {getTotal} from 'src/utils/orderUtils';
import {getSiteCurrency} from 'src/utils/selectors';
import Row from 'src/components/ui/Row';
import {canAccess} from 'src/utils/permissions';
import _ from 'lodash';
const bgcolor = '#EDEEF4';

export default function OrderDetailsSummury({order, receivePayment}) {
	const currency = getSiteCurrency();
	// let {oldTotal, newTotal} = getTotal(order);
	let oldTotal = getPriceNumber(order.oldSubTotal);
	let newTotal = getPriceNumber(order.subTotal);
	const shouldShowTotal = currency === 'د.ل';

	return (
		<Stack
			sx={{bgcolor: '#FAFAFA', p: 2, border: 'solid 0.3px #D9DBE4', borderRadius: 3}}
			flex={1}
			spacing={2}
		>
			<Typography variant="h6">ملخص الطلب</Typography>

			<Row label="مجموع المشتريات" value={`${newTotal} ${currency}`} />
			{!isNullOrEmpty(order.delivery_price) && (
				<Row label="سعر التوصيل" value={`${getPriceNumber(order.delivery_price)} د.ل`} />
			)}
			<Divider />
			{order.discount > 0 && <Row label="التخفيض" value={`${order.discount} ${currency}`} />}
			{/* {order.down_payment > 0 && <Row label="العربون" value={`${order.down_payment} ${currency}`} />} */}

			{shouldShowTotal && (
				<Row
					labelVariant="subtitle1"
					label="الاجمالي"
					bgcolor={bgcolor}
					value={
						<Card sx={{p: 1, bgcolor: '#8F8DA9'}}>
							<Typography sx={{color: 'white'}} variant="subtitle1">
								{getPriceNumber(order.total) + currency}
							</Typography>
						</Card>
					}
				/>
			)}

			{canAccess() && !order.is_cashier && (
				<>
					{!order.payment_received ? (
						<Button variant="outlined" onClick={() => receivePayment(true)}>
							تأكيد الاستلام
						</Button>
					) : (
						// don't show this option if order is made from cashier page ( we want to use (return, exchange) actions to handle it )
						<Button variant="outlined" onClick={() => receivePayment(false)}>
							الغاء الاستلام
						</Button>
					)}
				</>
			)}
		</Stack>
	);
}
