import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link as MuiLink} from '@mui/material';

export default function StyledLink({to, children}) {
	return (
		<MuiLink
			to={to}
			// color="inherit"
			underline="hover"
			component={RouterLink}
		>
			{children}
		</MuiLink>
	);
}
