import {Button, Checkbox, FormControlLabel, Grid, Stack, Typography} from '@mui/material';
import {OrderItem} from './Orders.item';
import useBulkActions from 'src/hooks/useBulkActions';
import {orderStatus} from 'src/utils/orderUtils';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import {deleteOrder, getOrdersStatistics, updateOrder} from 'src/utils/AdminApi';
import OrderStatusPicker from '../show/OrderStatusPicker';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog';
import {accountIs} from 'src/utils/siteUtils';
import {canAccess} from 'src/utils/permissions';
import {getSiteCurrency} from 'src/utils/selectors';
import useUrlFilters from 'src/hooks/useUrlFilters';
import {useQuery} from 'react-query';

export function OrdersList({
	refetch,
	bulkActionEnabled = true,
	showStats = true,
	orders,
	...props
}) {
	const {
		selections,
		setSelections,
		bulkInputs,
		setBulkInputs,
		reset,
		getHandleCheck,
		getMainCheckboxProps,
		selectAll,
	} = useBulkActions({
		data: orders,
		initialFormValues: {
			status: orderStatus.delivered.key,
		},
	});

	const {filtersQueryString, filters} = useUrlFilters();

	const {data = {total_delivery_price: 0, total_revenue: 0, orders_count: 0}} = useQuery(
		['orders-statistics', filtersQueryString],
		() => getOrdersStatistics(filtersQueryString),
		{
			refetchOnWindowFocus: false,
			enabled: showStats === true,
		},
	);
	const {total_down_payment, total_delivery_price, total_revenue, orders_count} = data;

	return (
		<>
			{/* <Checkbox {...getMainCheckboxProps()} /> */}

			<Stack justifyContent="space-between" direction="row">
				{showStats && canAccess() && (
					<Stack alignItems="center" flexWrap="wrap" my={1} spacing={1} direction="row">
						<Typography>عدد الطلبات:</Typography>
						<Typography fontWeight="bold">{orders_count || 0}</Typography>
						<Typography>مجموع المبيعات:</Typography>
						<Typography fontWeight="bold">
							{total_revenue || 0} {getSiteCurrency()}
						</Typography>
						<Typography>مجموع قيمة التوصيل:</Typography>
						<Typography fontWeight="bold">
							{total_delivery_price || 0} {getSiteCurrency()}
						</Typography>
						<Typography>المجموع الكلي:</Typography>
						<Typography fontWeight="bold">
							{total_revenue + total_delivery_price} {getSiteCurrency()}
						</Typography>
						{/* {filters.payment_received === 'false' && (
									<>
										<Typography>العربون: </Typography>
										<Typography fontWeight="bold">
											{total_down_payment} {getSiteCurrency()}
										</Typography>
									</>
								)} */}
					</Stack>
				)}

				{bulkActionEnabled && <Checkbox sx {...getMainCheckboxProps()} />}
			</Stack>

			<Grid container spacing={2} {...props}>
				{orders.map((order) => (
					<Grid key={order.id} item xs={12} sm={6} md={4}>
						<OrderItem
							order={order}
							handleCheck={getHandleCheck(order)}
							isChecked={Boolean(selections[order.id])}
						/>
					</Grid>
				))}
			</Grid>
			{!isNullOrEmpty(selections) && (
				<OrdersBulkActionForm
					refetch={refetch}
					selections={selections}
					bulkInputs={bulkInputs}
					reset={reset}
					setBulkInputs={setBulkInputs}
				/>
			)}
		</>
	);
}

function OrdersBulkActionForm({refetch, selections, bulkInputs, reset, setBulkInputs}) {
	const submit = async (e) => {
		e.preventDefault();
		if (bulkInputs.payment_received !== true) delete bulkInputs.payment_received;

		const ordersToUpdate = Object.keys(selections).map((id) => {
			const order = selections[id];
			return updateOrder(order.uuid, bulkInputs);
		});
		await Promise.all(ordersToUpdate);
		// clear changes from selected items
		// handle the case if user was changing row then try to use bulk action with that row
		// clear selections
		reset();
		refetch();
	};

	const deleteSelected = async () => {
		const ordersToDelete = Object.values(selections).map(({uuid}) => deleteOrder(uuid));
		await Promise.all(ordersToDelete);
		reset();
		refetch();
	};
	return (
		<Stack
			sx={{
				zIndex: 10,
				position: 'fixed',
				bottom: 50,
				left: '50%',
				transform: 'translate(-50%, -50%)',
				background: 'white',
				p: 2,
				borderRadius: 3,
				boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
			}}
			spacing={1}
			direction="row"
			flex={1}
		>
			<Stack flex={1} spacing={2} direction="row">
				{/* <TextField
					sx={{minWidth: inputMinSize}}
					type="number"
					label="السعر"
					name="price"
					value={bulkInputs.price}
					onChange={(e) => setBulkInputs({...bulkInputs, price: e.target.value})}
					InputLabelProps={{shrink: true}}
					/> */}
				<OrderStatusPicker
					label="الحالة"
					defaultStatus={bulkInputs.status}
					onStatusChange={(e) => setBulkInputs({...bulkInputs, status: e.target.value})}
				/>

				<FormControlLabel
					control={
						<Checkbox
							onChange={(e) => setBulkInputs({...bulkInputs, payment_received: e.target.checked})}
							name="payment_received"
							checked={bulkInputs.payment_received}
						/>
					}
					label="تسوية"
				/>

				<Button onClick={submit}>حفظ</Button>
			</Stack>

			<DeleteAlertDialog onDelete={deleteSelected} />
		</Stack>
	);
}
