const {getLoggedInUser} = require('./selectors');
// format: controller.action
export const canAccess = (controllerAction = '') => {
	let user = getLoggedInUser();

	if (!user) return false;

	if (user.isOwner === true) return true;
	else
		return !!user.allowed_actions.find(
			(action) => controllerAction.toLowerCase() === `${action.identifier}`.toLowerCase(),
		);
};

export const isOrderAssignee = (order) => {
	const user = getLoggedInUser();
	if (!user) return false;

	return order?.assignee?.some((assignee) => assignee.id == user.id);
};

export const canAccessOrder = (order) => {
	const user = getLoggedInUser();
	if (!user) return false;

	return user.isOwner || isOrderAssignee(order);
};
