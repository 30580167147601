// material
// components
import {Box, Container, Stack, Tab, Tabs, Typography} from '@mui/material';
import {useState} from 'react';
import Page from 'src/components/Page';
import {TabPanel} from 'src/components/TabPanel';
import AdditionalInfoForm from 'src/components/_dashboard/UpdateSite/AdditionalInfoForm.js';
import BasicInfoForm from 'src/components/_dashboard/UpdateSite/BasicInfoForm.js';
import VisitSite from 'src/components/VisitSite.js';
import StoreDesign from '../components/StoreDesign.js';

export default function UpdateSite() {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<Page title="المتجر">
			<Container>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h3">المتجر </Typography>
					<VisitSite text="عرض المتجر" />
				</Stack>

				<Box sx={{borderBottom: 1, mb: 1, borderColor: 'divider'}}>
					<Tabs
						variant="scrollable"
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
					>
						<Tab label="اعدادات الموقع الاساسية" />
						<Tab label="اعدادات اضافية" />
						<Tab label="تصميم الموقع" />
					</Tabs>
				</Box>
				<TabPanel index={0} value={value}>
					<BasicInfoForm />
				</TabPanel>
				<TabPanel index={1} value={value}>
					<AdditionalInfoForm />
				</TabPanel>
				<TabPanel index={2} value={value}>
					<StoreDesign />
				</TabPanel>
			</Container>
		</Page>
	);
}
