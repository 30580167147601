import {Grid, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {getGeneralStatistics} from 'src/utils/AdminApi';
import {getCountString2} from 'src/utils/helperMethods';
import {getSiteCurrency} from 'src/utils/selectors';
import {accountIs} from 'src/utils/siteUtils';
import ToggleButtons from '../../components/ToggleButtons';
import StatsItem from './StatsItem';
import {DateTime} from 'luxon';
import {formatAmountString} from 'vetrina-js-utils/moneyUtils';
import {getNumber} from 'src/utils/numberUtils';
import {Link} from 'react-router-dom';
import InfoTip from 'src/components/ui/InfoTip';

const dateRangeFilters = {
	today: {
		key: 'today',
		label: 'اليوم',
		fromDate: DateTime.utc().toISODate(),
		toDate: DateTime.utc().plus({days: 1}).toISODate(),
	},
	thisMonth: {
		key: 'thisMonth',
		label: 'الشهر',
		fromDate: DateTime.utc().startOf('month').toISODate(),
		toDate: DateTime.utc().startOf('month').plus({months: 1}).toISODate(),
	},
	thisYear: {
		key: 'thisYear',
		label: 'السنة',
		fromDate: DateTime.utc().startOf('year').toISODate(),
		toDate: DateTime.utc().startOf('year').plus({years: 1}).toISODate(),
	},
};

const toggleButtons = Object.values(dateRangeFilters).map((date) => ({
	label: date.label,
	value: date.key,
}));

export const initalStats = {
	numberOfCustomers: 0,
	totalIncome: 0,
	top5Products: [],
	numberOfDeliveredOrders: 0,
	numberOfCancelledOrders: 0,
	numberOfReturnedOrders: 0,
	totalIncomeOfDeliveredOrders: 0,
	numberOfActiveOrders: 0,
	totalCosts: 0,
	productsCount: 0,
	expected_revenue: 0,
	settled_orders_revenue: 0,
	settled_orders_count: 0,
};

export default function DateRangeStats() {
	const [selectedRange, setSelectedRange] = useState(dateRangeFilters.thisMonth);
	const currency = getSiteCurrency();
	// const stats = initalStats;
	const {data: stats = initalStats} = useQuery(
		[selectedRange.key],
		() => getGeneralStatistics(selectedRange.fromDate, selectedRange.toDate),
		{
			// we will try to fetch new stats after 10s of last attempt
			// staleTime: 1000 * 10,
			refetchOnWindowFocus: false,
			// refetchOnMount: false,
			enabled: selectedRange.fromDate != null,
		},
	);

	return (
		<div spacing={2}>
			<Stack mb={2} spacing={1} alignItems="center" direction="row" justifyContent="space-between">
				<Typography variant="h4">المبيعات</Typography>
				<ToggleButtons
					onChange={(e) => setSelectedRange(dateRangeFilters[e.target.value])}
					value={selectedRange.key}
					buttons={toggleButtons}
				/>
			</Stack>

			<Grid spacing={2} container>
				<StatsItem
					key="sales_stats"
					label="طلبات تم تسويتها"
					value={formatAmountString(stats.settled_orders_revenue, currency)}
					subValue={`من ${getCountString2(stats.settled_orders_count, 'طلب', 'طلبات') ?? 0}`}
					info={'الطلبات التي تم تسويتها مالياً'}
				/>
				<StatsItem
					key="sales_stats"
					label="طلبات تم تسليمها"
					value={formatAmountString(stats.totalIncomeOfDeliveredOrders, currency)}
					subValue={`من ${getCountString2(stats.numberOfDeliveredOrders, 'طلب', 'طلبات') ?? 0}`}
					info={'الطلبات التي تم توصيلها او استلامها'}
				/>
				<StatsItem
					key="stats2"
					label="طلبات فعالة"
					value={formatAmountString(stats.totalIncome, currency)}
					subValue={`من ${getCountString2(stats.numberOfActiveOrders, 'طلب', 'طلبات') ?? 0}`}
					info={'الطلبات التي قيد المراجعة او قيد التجهيز او قيد التوصيل '}
				/>
				<StatsItem key="stats4" label="طلبات ملغية" value={stats.numberOfCancelledOrders} />
				<StatsItem key="stats5" label="طلبات راجعة" value={stats.numberOfReturnedOrders} />
				<StatsItem key="productsCount" label="عدد المنتجات" value={stats.productsCount} />
				<StatsItem
					key="totalCosts"
					label="التكاليف"
					value={formatAmountString(stats.totalCosts, currency)}
				/>

				<StatsItem
					key="profit"
					label="الارباح"
					value={formatAmountString(stats.totalIncomeOfDeliveredOrders - stats.totalCosts, currency)}
					info={
						'الفرق بين مجموع مبيعات الطلبات التي تم توصيلها ومجموع المشتريات التي تم ادخالها في المنظومة'
					}
				/>
				<StatsItem
					key="profit"
					label="اجمالي المخزون"
					value={formatAmountString(stats.expected_revenue, currency)}
					info={'مجموع بيع الكميات المتوفرة'}
				/>
			</Grid>

			{/* <InfoTip title={'هذا الرقم يعبر عن مجموع الطلبات التي تم تسويتها بالاضافة الي العربون'} /> */}

			{/* {accountIs('feza', true) && (
				<>
					<Typography mt={2}>المبالغ التي تم استلامها + العربون {getNumber(stats.cash)}</Typography>
					<Stack spacing={2}>
						<Link to={`/orders?payment_received=1`}>عرض الطلبات اللي تم تسويتها</Link>
						<Link to={`/orders?payment_received=0&down_payment_gte=1`}>
							عرض الطلبات اللي ليها عربون ولم يتم تسويتها
						</Link>
					</Stack>
				</>
			)} */}
		</div>
	);
}
