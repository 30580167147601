const {default: store} = require('src/redux-store');

export function getSiteCurrency() {
	return store.getState().admin.user.OwnedWebsite?.config?.currency ?? 'د.ل';
}

export function getLoggedInUser() {
	return store.getState().admin.user;
}
export function getSite() {
	return store.getState().admin.user?.OwnedWebsite;
}
export const isShippingProviderEnabled = (provider) => {
	return (
		store.getState().admin.user?.OwnedWebsite?.config?.shippingProviders?.[provider]?.enabled === true
	);
};
