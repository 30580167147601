import {includes} from 'lodash';
import {calcPriceAfterDiscount} from './discountUtils';
import {getNumber, sumBy} from './numberUtils';

export const orderStatus = {
	review: {
		key: 'review',
		label: 'قيد المراجعة',
	},
	preparing: {
		key: 'preparing',
		label: 'قيد التجهيز',
	},
	delivering: {
		key: 'delivering',
		label: 'قيد التوصيل',
	},
	delivered: {
		key: 'delivered',
		label: 'تم التسليم',
	},
	returned: {
		key: 'returned',
		label: 'راجع',
	},
	cancelled: {
		key: 'cancelled',
		label: 'ملغي',
	},
};

export const orderStatusOptions = Object.values(orderStatus);

export const getTotal = (order) => {
	return (
		order?.items?.reduce(
			({oldTotal, newTotal}, item) => {
				const finalPrice = calcPriceAfterDiscount(item.price, item.discount);
				return {
					oldTotal: finalPrice * item.quantity + oldTotal,
					newTotal: item.isDeleted ? newTotal : finalPrice * item.quantity + newTotal,
				};
			},
			{oldTotal: 0, newTotal: 0},
		) ?? {}
	);
};

export const sumOrdersTotal = (orders) => {
	return sumBy(orders, (order) => order.total);
};
export const sumOrdersSubTotal = (orders) => {
	return sumBy(orders, (order) => order.subTotal);
};
/**
 * 
 * {
    "id": "CART_NOT_VALID",
    "errors": [
        {
            "product": {
                "id": 517,
                "name": "no quantity available",
                "sku": "",
                "details": "",
                "price": 1,
                "cost": null,
                "quantity": 0,
                "category": null,
                "attrs": [],
                "inputs": null,
                "rating": 0,
                "isDeleted": false,
                "allow_out_of_stock_sell": false,
                "created_at": "2022-06-02T11:19:49.595Z",
                "updated_at": "2022-06-02T11:19:49.619Z",
                "images": [],
                "main_image": null,
                "variations": []
            },
            "errors": {
                "productOutOfStock": true
            },
            "item": {
                "id": 517,
                "name": "no quantity available",
                "sku": "",
                "details": "",
                "price": 1,
                "cost": null,
                "quantity": 0,
                "category": null,
                "attrs": [],
                "inputs": null,
                "rating": 0,
                "isDeleted": false,
                "allow_out_of_stock_sell": false,
                "created_at": "2022-06-02T11:19:49.595Z",
                "updated_at": "2022-06-02T11:19:49.619Z",
                "images": [],
                "main_image": null,
                "variations": []
            }
        }
    ],
    "updatedItems": []
}
 */

// return object represent products that causing the error
/**
 *
 * @param {*} errors {productNotExist:true,priceChange:true}
 * @returns error1, erro2.
 */
export const getOrderItemsErrors = (errors) => {
	// type of errors productNotExist, isDeleted, productOutOfStock, variationNotExist, variationOutOfStock, priceChange, invalidQuantity
	let errorString = '';
	// loop through keys
	for (let error in errors) errorString += `${orderItemsErrors[error]}, `;

	return errorString.replace(/,([^,]*)$/, '.');
};

export const orderItemsErrors = {
	priceChange: 'يوجد تغير في السعر',
	isDeleted: 'تم حذف المنتج',
	productOutOfStock: 'نفدت الكمية',
	variationOutOfStock: 'نفدت الكمية',
	variationNotExist: 'لم يتم العثور علي المنتج',
	productNotExist: 'لم يتم العثور علي المنتج',
	invalidQuantity: 'الكمية المدخلة اكبر من الكمية الموجودة في المخزن',
};

export const orderIsCanceledOrReturned = (order) =>
	includes(['cancelled', 'returned'], order.status);

export const getOrdersListString = (orders) => {
	return orders
		.map(
			(order) =>
				`اسم الزبون: ${order.customer.name}\nرقم الهاتف: ${order.customer.phone}\nالعنوان:${
					order.address
				}\nقيمة المشتريات:${order.total_price}\nسعر التوصيل:${getNumber(
					order.delivery_price,
				)}\nالمجموع:${getNumber(order.delivery_price) + order.total_price}\nملاحظات: ${order.note}`,
		)
		.join('\n===========\n');
};
