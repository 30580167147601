import {Card, CircularProgress, Divider, Stack, Typography} from '@mui/material';
import React from 'react';
import {shippingProviders} from 'src/data/shipping-providers';
import {isNullOrEmpty} from 'src/utils/helperMethods';
import Row from 'src/components/ui/Row';
import TableWrapper from 'src/components/wrappers/TableWrapper';

const bgcolor = '#EDEEF4';
export default function OrderShippingDetails({shipping, provider, logs, shippingDataIsLoading}) {
	return (
		<Card id="shipping-details" sx={{padding: 4, my: 2}}>
			<Typography sx={{mb: 2}} variant="h5">
				معلومات الشحنة
			</Typography>
			{shippingDataIsLoading ? (
				<Stack my={2} alignItems="center">
					<CircularProgress size={22} color="primary" />
					<Typography>يرجي الانتظار</Typography>
				</Stack>
			) : !isNullOrEmpty(shipping) ? (
				<Stack spacing={1} sx={{mb: 2}}>
					<Row label="شركة الشحن" value={shippingProviders?.[provider]?.name} />
					<Row bgcolor={bgcolor} label="رقم الشحنة" value={shipping.shippingId} />
					<Row label="حالة الشحنة" value={shipping.status} />
					{shipping.shippingPayment && (
						<Row bgcolor={bgcolor} label="تكلفة الشحن" value={shipping.shippingPayment + ' د.ل'} />
					)}
					{/* <Row label="مسار الشحنة" value={`من ${shipping.cityFrom} الي ${shipping.cityTo}`} /> */}
					<Row label="تاريخ تسجيل الشحنة" value={shipping.createdOn} />
				</Stack>
			) : (
				<p>لا يوجد بيانات</p>
			)}

			{!isNullOrEmpty(logs) && (
				<>
					<Divider />
					<Typography sx={{my: 2}} variant="h5">
						تتبع الشحنة
					</Typography>
					<TableWrapper
						align="start"
						headerColumns={['التفاصيل', 'التاريخ والوقت']}
						dataRows={logs.map((item) => [item.description, item.date_time])}
					/>
				</>
			)}
		</Card>
	);
}
