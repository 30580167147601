const {calcPriceAfterDiscount} = require('./discountUtils');
const {getPriceString, getCountStringCustom, isNullOrEmpty} = require('./helperMethods');
const {getNumber, sumBy} = require('./numberUtils');
const {getSiteCurrency} = require('./selectors');

export const getCartItemTotalPrice = (item) =>
	calcPriceAfterDiscount(item.price, item.discount) * getNumber(item.selectedQuantity, -1);

export const getCartItemsTotalPrice = (cartItems) => {
	return sumBy(cartItems, getCartItemTotalPrice);
};

export const getCartItemsTotalQuantity = (cartItems) => {
	const sum = sumBy(cartItems, (item) => getNumber(item.selectedQuantity, 1));

	return getCountStringCustom(sum, 'قطعة واحدة فقط', 'قطعتان', 'قطع', false);
};
