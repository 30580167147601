//Create dataStorage
const saveDataToStorage = (name, data) => {
	localStorage.setItem(name, data);
};

const getDataFromStorage = (name) => {
	return localStorage.getItem(name);
};

const admin_token_key = '@vetrina_admin';
const token_key = '@vetrina';
export const storeAdminToken = (token) => saveDataToStorage(admin_token_key, token);
export const storeToken = (token) => saveDataToStorage(token_key, token);
export const getAdminToken = () => getDataFromStorage(admin_token_key) ?? '';
export const getToken = () => getDataFromStorage(token_key) ?? '';
export const isLoggedIn = () => getDataFromStorage(token_key)?.length > 0 ?? false;

export const adminIsLoggedIn = () => getDataFromStorage(admin_token_key)?.length > 0 ?? false;
export const removeToken = () => localStorage.removeItem(token_key);
export const removeAdminToken = () => localStorage.removeItem(admin_token_key);

export const saveUser = (userObject) => localStorage.setItem('user', JSON.stringify(userObject));
export const getSavedUser = () => JSON.parse(localStorage.getItem('user'));
export const removeSavedUser = () => localStorage.removeItem('user');
